.treeView {
  list-style: none;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: var(--text-default);
}
.treeView a {
  text-decoration: none;
}
.treeView ul li {
  padding-left: 2.5rem;
}