.lc-checklist {
  position: relative;
  border-radius: 0.3rem;
  font-size: 12px;
}
.lc-checklist > .filter {
  margin-left: 10px;
  margin-right: 1.25rem;
}


.lc-checklist.no-margin > .filter {
  margin-left: 0px;
  margin-right: 1.25rem;
}

.lc-checklist > .filter ::-moz-placeholder {
  font-style: italic;
  color: #8b90a3;
}
.lc-checklist > .filter ::placeholder {
  font-style: italic;
  color: #8b90a3;
}
.lc-checklist > .header {
  padding: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.lc-checklist > .header > .input-toggle,
.lc-checklist > .header > .input-checkbox {
  margin-right: 5px;
}
.lc-checklist > .header > .title {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--text-default);
}
.lc-checklist > .header > .title > i {
  margin-left: 0.5rem;
}
.lc-checklist > .list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.lc-checklist.no-margin > .list {
  height: calc(100vh - 260px) !important;
}

.lc-checklist > .list > .item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 0.25rem;
  background-color: #fff;
  margin-bottom: 3px;
  color: #0580ce;
}
.lc-checklist > .list > .item > .input-toggle,
.lc-checklist > .list > .item > .input-checkbox {
  margin: 0 10px 0 0;
}
.lc-checklist > .list > .item:nth-child(even) {
  background-color: transparent;
}
.lc-checklist > .list > .item:hover {
  background-color: var(--background-lightest);
}
.lc-checklist > .list > .item > label {
  font-size: 1rem;
}