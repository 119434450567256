.pp-table > .header {
  display: flex;
  margin: 0;
  padding: 5px 0px;
  width: 100%;
  display: flex;
  border-left: 5px solid #fff;
}
.pp-table > .header > .cells {
  display: flex;
  width: 100%;
}
.pp-table > .header > .cells > .cell {
  font-family: "Ubuntu", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.pp-table > .header > .cells > .cell.sortable {
  cursor: pointer;
}
.pp-table > .header > .cells > .cell.sortable:hover {
  background-color: #eee;
}
.pp-table > .header > .cells > .cell.left {
  justify-content: flex-start;
  text-align: start;
}
.pp-table > .header > .cells > .cell.center {
  justify-content: center;
  text-align: center;
}
.pp-table > .header > .cells > .cell.right {
  justify-content: flex-end;
  text-align: end;
}
.pp-table > .header > .functions {
  width: 28px;
}
.pp-table > .header > .scroll {
  width: 8px;
}
.pp-table > .body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.pp-table > .body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #eee;
}
.pp-table > .body::-webkit-scrollbar-thumb {
  background: #cccccf;
  border-radius: 0.25rem;
}
.pp-table > .body::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
.pp-table > .body::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}
.pp-table > .body > .row {
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  gap: 0;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: #fff;
}
.pp-table > .body > .row > .selectable {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  cursor: pointer;
}
.pp-table > .body > .row > .cells {
  display: flex;
  align-items: center;
  width: 100%;
}
.pp-table > .body > .row > .cells > .cell {
  font-size: 14px;
  color: #2E1956;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 3px;
}
.pp-table > .body > .row > .cells > .cell.left {
  justify-content: flex-start;
}
.pp-table > .body > .row > .cells > .cell.right {
  justify-content: flex-end;
}
.pp-table > .body > .row > .cells > .cell.center {
  justify-content: center;
}
.pp-table > .body > .row > .cells > .cell.between {
  justify-content: space-between;
}
.pp-table > .body > .row > .cells > .cell.tiny {
  font-size: 0.6rem;
}
.pp-table > .body > .row > .cells > .cell.small {
  font-size: 0.7rem;
}
.pp-table > .body > .row > .cells > .cell.medium {
  font-size: 0.8rem;
}
.pp-table > .body > .row > .cells > .cell.large {
  font-size: 1rem;
}
.pp-table > .body > .row > .cells > .cell.big {
  font-size: 1.2rem;
}
.pp-table > .body > .row > .functions {
  width: 28px;
}
.pp-table > .body > .row:hover {
  background-color: #eee;
}
.pp-table > .body > .row:hover:not(.status) {
  border-left-color: #eee !important;
}
.pp-table > .body.highdensity > .row {
  min-height: 28px;
  margin-bottom: 1px;
}
.pp-table > .body.mediumdensity > .row {
  min-height: 38px;
  margin-bottom: 4px;
}
.pp-table > .body.lowdensity > .row {
  min-height: 60px;
  margin-bottom: 4px;
}
.pp-table > .body > .loader {
  margin-top: 34px;
  display: flex;
  justify-content: center;
}
.pp-table.debug > .header {
  background-color: orange;
}
.pp-table.debug > .header > .cells > .cell:nth-child(even) {
  background-color: blue;
}
.pp-table.debug > .header > .cells > .cell:nth-child(odd) {
  background-color: green;
}
.pp-table.debug > .header > .scroll {
  background-color: red;
}
.pp-table.debug > .body .row {
  background-color: orangered;
}
.pp-table.debug > .body .row > .cells > .cell:nth-child(even) {
  background-color: blue;
}
.pp-table.debug > .body .row > .cells > .cell:nth-child(odd) {
  background-color: green;
}