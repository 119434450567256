.iconvaluecardrow {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  background-color: white;
  /* box-shadow: 16px 16px 16px rgba(58, 79, 135, 0.06); */
  border-radius: 1rem;
  justify-content: space-evenly;
}
.iconvaluecardrow.link:hover {
  background-color: #ddd;
}
.iconvaluecardrow > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.iconvaluecardrow > .header > .title {
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  color: #8b90a3;
}
.iconvaluecardrow > .body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.iconvaluecardrow > .body > .icon {
  width: 55px;
  font-size: 50px;
}
.iconvaluecardrow > .body > .right {
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  text-align: right;
}
.iconvaluecardrow > .body > .right > .data {
  font-weight: 600;
  font-size: 30px;
}
.iconvaluecardrow > .body > .right > .data > .unity {
  display: inline-block;
  font-weight: 600;
  font-size: 0.8rem;
}
.iconvaluecardrow > .body > .right > .data > .unity > .label {
  font-size: 1.5rem;
}
.iconvaluecardrow > .body > .right > .data > .unity > .lc-tooltip {
  margin-left: 4px;
  color: #0580ce;
}
.iconvaluecardrow > .body > .right > .description {
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--text-default);
}