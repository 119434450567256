.lc-iconlink {
    width: 30px;
    height: 30px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    position: relative;
}

    .lc-iconlink.filtered {
        color: var(--color-secondary-lime);
    }

    .lc-iconlink.unfiltered {
        color: var(--text-default);
    }

    .lc-iconlink.small {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        font-size: 20px;
    }

    .lc-iconlink.big {
        width: 42px;
        height: 42px;
        border-radius: 21px;
        font-size: 26px;
    }

    .lc-iconlink > .content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .lc-iconlink > .content > .badge {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: -moz-fit-content;
            width: fit-content;
            min-width: 19px;
            height: 14px;
            padding: 0 2px;
            border-radius: 7px;
            top: -5px;
            left: 18px;
            right: 0;
        }

            .lc-iconlink > .content > .badge > span {
                font-size: 10px;
                font-weight: bold;
            }

    .lc-iconlink > .tooltip {
        visibility: hidden;
        width: inherit;
        position: absolute;
        z-index: 9999;
    }

        .lc-iconlink > .tooltip.delay {
            transition-delay: 0.5s;
            transition-property: visibility;
        }

        .lc-iconlink > .tooltip > .body {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transform: translateY(38px);
            filter: drop-shadow(0px 0px 3px rgba(32, 47, 47, 0.36));
        }

            .lc-iconlink > .tooltip > .body.inline-left {
                transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, -1, 0, 1, 0, -35, -5, 0, 1);
            }

            .lc-iconlink > .tooltip > .body.inline-right {
                transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, -1, 0, 1, 0, 200, -5, 0, 1);
            }
            .lc-iconlink > .tooltip > .body:has(.super-info) {
                transform: translateY(86px);
            }


            .lc-iconlink > .tooltip > .body > .arrow {
                z-index: 7501;
                align-self: center;
                width: 10px;
                height: 10px;
                background-color: var(--background-lightest);
                transform: rotate(45deg) translate(5px, 5px);
            }

                .lc-iconlink > .tooltip > .body > .arrow.inline-left {
                    transform: rotate(50deg) translate(20px, 0px);
                }
                .lc-iconlink > .tooltip > .body > .arrow.inline-right {
                    transform: rotate(50deg) translate(-95px, 133px);
                }

            .lc-iconlink > .tooltip > .body .content {
                align-self: center;
                z-index: 7500;
                width: -moz-max-content;
                width: max-content;
                max-width: 350px;
                height: auto;
                background-color: var(--background-lightest);
                border: 0;
                border-radius: 4px;
                padding: 8px 12px;
                font-size: 11px;
                font-weight: 400;
                color: var(--text-default);
            }

                .lc-iconlink > .tooltip > .body .content.left {
                    align-self: flex-start;
                }

                .lc-iconlink > .tooltip > .body .content.right {
                    align-self: flex-end;
                }

                .lc-iconlink > .tooltip > .body .content.inline-left {
                    align-self: flex-end;
                    padding: 5px 16px;
                }
                .lc-iconlink > .tooltip > .body .content.inline-right {
                    align-self: flex-end;
                    padding: 5px 16px;
                }

    .lc-iconlink:hover {
        background-color: #F0F3FF;
    }

        .lc-iconlink:has(.lci-checkbox):hover {
            background-color: transparent;
        }

        .lc-iconlink:hover .tooltip {
            visibility: visible;
        }

    .lc-iconlink:not(:hover) .tooltip {
        visibility: hidden;
        transition-delay: unset;
    }

    .lc-iconlink.disabled {
        color: #666;
        cursor: not-allowed;
    }
    .lc-iconlink.notALink {
        cursor: auto;
    }

        .lc-iconlink.disabled:hover {
            background-color: #eee;
        }
        .lc-iconlink.notHover:hover {
            background-color: transparent;
            cursor: pointer;
        }

    .lc-iconlink.debug {
        background-color: red;
    }

        .lc-iconlink.debug > .content {
            background-color: blue;
        }

        .lc-iconlink.debug > .tooltip {
            background-color: green;
        }
    .lc-iconlink >.content > i.degrade {
        font-size: 30px; 
        background-image: linear-gradient(191.87deg, #75F9FB 6.38%, rgb(117, 249, 69) 92.76%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-color: red;
        
    }

    
    .lc-iconlink.pulse {
        box-shadow: 0 0 0 rgba(255,0,0, 0.6);
        animation: pulse-red 2s infinite;
        border-radius: 50%;
        
    }

    .lc-iconlink.pulse:hover {                
        animation: none; 
    }

    .AiMenu:has(.pp-dropmenu) .lc-iconlink {
        animation: none; 
    }

    .fields:has(.allAI.open) .lc-iconlink {
        animation: none;
    }

    @-webkit-keyframes pulse-red {
        0% {
          -webkit-box-shadow: 0 0 0 0 rgba(255,0,0, 0.6);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(255,0,0, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,0,0, 0);
        }
      }
      @keyframes pulse-red {
        0% {
          -moz-box-shadow: 0 0 0 0 rgba(255,0,0, 0.6);
          box-shadow: 0 0 0 0 rgba(255,0,0, 0.6);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(255,0,0, 0);
            box-shadow: 0 0 0 10px rgba(255,0,0, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(255,0,0, 0);
            box-shadow: 0 0 0 0 rgba(255,0,0, 0);
        }
      }
