.ppdropdown .ppdropdown-container {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--text-default);
    letter-spacing: normal;
    line-height: normal;
    min-height: 28px;
    /* height: 28px; */
    cursor: pointer;
}

.ppdropdown > label {
    font-size: 0.75rem;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 4px;
}

/*.ppdropdown .ppdropdown__menu-list {
    max-height: 20vh;
}*/

.ppdropdown .ppdropdown__menu-list::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    z-index: 99;
}

.ppdropdown .ppdropdown__menu-list::-webkit-scrollbar-thumb {
    background: #cccccf;
    border-radius: 0.25rem;
}

    .ppdropdown .ppdropdown__menu-list::-webkit-scrollbar-thumb:hover {
        background: #ddd;
    }

.ppdropdown .ppdropdown__menu-list::-webkit-scrollbar-track {
    border-radius: 0.25rem;
}




.ppdropdown .ppdropdown__control {
    min-height: 28px;
    /* height: 28px; */
    cursor: pointer;
}

.ppdropdown .ppdropdown__value-container {
    /* height: 26px; */
    padding: 0 4px;
    max-height: 75px;
    overflow-y: auto;
}
.ppdropdown .ppdropdown__value-container::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    z-index: 99;
}
.ppdropdown .ppdropdown__value-container::-webkit-scrollbar-thumb {
    background: #cccccf;
    border-radius: 0.25rem;
}
.ppdropdown .ppdropdown__value-container::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.ppdropdown .ppdropdown__value-container::-webkit-scrollbar-track {
border-radius: 0.25rem;
}


.ppdropdown .ppdropdown__option {
    padding: 4px 8px;
    cursor: pointer;
    color: var(--text-default);
    font-size: 13px;
}

.ppdropdown .ppdropdown__input {
    margin: 0px;
    padding: 0px;
}

.ppdropdown .ppdropdown__indicator-separator {
    display: none;
}

.ppdropdown .ppdropdown__indicator {
    padding: 4px;
}

/* .ppdropdown .ppdropdown__indicators {
    min-height: 26px;
} */


.ppdropdown .ppdropdown__control--is-disabled {
    background: #ebebeb !important;
}