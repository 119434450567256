.page-automacoes {
    height: 100%;
}

.title-default {
    color: var(--text-light);
    font-family: "Ubuntu", sans-serif;
}

.related-container .MuiBadge-root,
.details-related-automations .MuiBadge-root {
    margin-right: 1.6rem;
}

.page-automacoes .MuiBadge-root .MuiBadge-colorPrimary,
.related-container .MuiBadge-root .MuiBadge-colorPrimary,
.details-related-automations .MuiBadge-root .MuiBadge-colorPrimary {
    background-color: var(--color-secondary-mint);
    color: var(--text-default);
}

.optionsGroup .MuiBadge-root {
    margin-top: 0.2rem;
    margin-right: 0.5rem;
}

.optionsGroup .MuiBadge-root .MuiBadge-colorSecondary {
    background-color: #ebebeb;
    color: #babdc6;
}

.page-automacoes .customBadgeWeek span,
.related-container .customBadgeWeek span,
.details-related-automations .customBadgeWeek span {
    border: 0.5px solid white;
    background-color: #e4eaec;
    color: #76838f;
    font-size: 0.7rem;
    word-break: keep-all !important;
    font-weight: 100;
    height: 1.2rem;
    width: 2rem;
}

.page-automacoes .checkbox-dias {
    background-color:var(--color-secondary-lime);
}

.related-container {
    width: 25%;
    height: 50vh;
    background-color: #f1f5fb;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
}

.related-resource-label {
    font-size: 14px;
    margin: 10px 0;
}

.related-container::-webkit-scrollbar {
    width: 8px;
    height: 0;
}

.related-container::-webkit-scrollbar-thumb {
    background: #cccccf;
    height: 8px;
    border-radius: 4px;
}

.related-container::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.related-container::-webkit-scrollbar-track {
    border-radius: 4px;
}

.related-resource-card {
    box-shadow: none !important;
    border-left: 4px solid;
    margin-bottom: 0.5rem;
}

.related-resource-link {
    font-size: 12px;
    margin-left: 4px;
    color: var(--color-secondary-cyan);
	cursor: pointer;
	text-decoration: underline;
}

.related-title,
.resize-review-title {
    color: #8B90A3;
    font-family: "Ubuntu", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.04rem;
    min-width: 60px;
}

.related-title {
    line-height: 160%;
}

.titulo-form {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-default);
    flex: none;
    order: 0;
    flex-grow: 0;
    height: 16px;
    position: static;
    left: 0px;
    top: 0px;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
}

.details-resource-container {
    margin-top: 1rem;
    display: inline-block;
}

.details-resource-header-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px;
}

.details-resource-header-line .details-resource-title {
    display: flex;
    align-items: center;
}

.details-resource-header-line .details-resource-name {
    margin-left: 8px;
}

.details-resource-line {
    display: flex;
    align-items: start;
    font-size: 12px;
    margin-bottom: 0.5rem;
}

.details-resource-line label {
    color: gray;
}

.details-resource-line span {
    margin-right: 1rem;
}

.resize-review-container {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 50vh;
    overflow-y: hidden;
}

.resize-review-dashboard {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.resize-review-title {
    margin-bottom: 1rem;
    line-height: 120%;
}

.resize-review-grid {
    width: 25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0 1.5rem 1rem;
}

.resize-review-col {
    width: 100%;
    margin: 0;
}

.resize-review-col span {
    position: relative;
    display: block;
    font-size: 12px;
    white-space: nowrap;
    padding: 5px;
    text-align: right;
}

.resize-review-col:first-of-type span {
    text-align: left;
}

.resize-review-col span:nth-child(even):not(:nth-child(2)) {
    background-color: #f5f5f5;
}

.resize-review-col:last-child span:not(:nth-child(1)) {
    font-weight: 600;
}

.resize-review-sizeline {
    border-bottom: 2px solid #f5f5f5;
    font-weight: 600;
}

.resize-review-costarrow {
    width: 20%;
    display: flex;
    justify-content: center;
}

.resize-review-costarrow,
.resize-review-costsummary {
    position: relative;
    margin: auto;
}

.resize-review-costsummary {
    text-align: center;
    width: 40%;
    display: block;
    color: #8B90A3;
    font-family: "Ubuntu", sans-serif;
}

.resize-review-costsummary span:first-child,
.resize-review-costsummary span:last-child {
    font-size: 0.8rem;
    white-space: nowrap;
}

.resize-review-costsummary-value {
    font-size: 2rem;
    color: #5F5DAD;
}

.details-related-automations {
    margin-top: 30px;
}

.rdButton {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.detailLink {
    color: var(--color-secondary-cyan);
	text-decoration: underline;
}

.detailLink:hover {    
    cursor: pointer;
}

.detailRow {
    padding: 7px 0;
}

.detailRowList {
    padding: 3.5px 0;
}

.auditTimestamp {
    color: green;
}

.auditInfoContainer {
    padding: 5px;
}

.auditInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.auditInfoLabels {
    width: 200px;
}

.ticketSummary {
    font-size: 14px;
}

.ticketSummary p {
    padding: 5px;
}

.filterPanelControls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.filterPanelFields {
    margin: 0;
}

.filterPanelFields div {
    padding: 2px 0;
}

/* DATA TABLE*/
.automationIdColumn {
    min-width: 0;
}

.automationIdIcon {
    min-width: 20px;
    padding-left: 4px;
}

.automationIdColumn,
.automationIdIcon {
    align-items: center;
}

.automationIdColumn,
.automationIdIcon,
.hourScheduledColumn,
.weekDaysScheduledColumn,
.relatedWeekDaysScheduledColumn,
.relatedHourScheduledColumn,
.actionColumn {
    display: flex;
}

.automationIdColumn,
.createdAtColumn,
.lastRunColumn,
.related-lastRunColumn .createdByColumn {
    white-space: nowrap;
}

.hourScheduledColumn div,
.related-hourScheduledColumn div {
    margin-top: 1px;
}

.statusEnabled {
    color: #3ebb3f
}

.statusDisabled {
    color: #dc291e
}