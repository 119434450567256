.errorMessage {
    font-size: 1rem;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    color: #8b90a3;
}

.sadDog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 1rem;
  height: 15rem;
}

.errorBody {
    width: 100%;
    height: 25%;
    margin-top: '4rem';
    display: 'flex';
    justify-content: 'center'
}