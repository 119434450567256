.doughnut-chart {
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.6rem;
  border-radius: 1rem;
  height: 100%;
  box-shadow: 16px 16px 16px rgba(58, 79, 135, 0.06);
}

.doughnut-chart.no-shadown {
  box-shadow: none !important;
  background-color: transparent !important;
}

.doughnut-chart > .title {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #8b90a3;
  margin-bottom: 0.6rem;
}
.doughnut-chart > .chart {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.doughnut-chart > .chart > .spacing-canvas-small {
  width: calc(12.5% + 0.5rem) !important;
}
.doughnut-chart > .chart > .spacing-canvas-medium {
  width: calc(26.3157894737% + 3rem) !important;
}
.doughnut-chart > .chart > .spacing-canvas-large {
  width: calc(25% + 5rem) !important;
}
.doughnut-chart > .chart > .spacing-legend-small {
  width: calc(12.5% + 0.5rem) !important;
  padding-left: 14px;
}
.doughnut-chart > .chart > .spacing-legend-medium {
  width: calc(26.3157894737% + 3rem) !important;
  padding-left: 25px;
}
.doughnut-chart > .chart > .spacing-legend-large {
  width: calc(25% + 5rem) !important;
  padding-left: 90px;
}
.doughnut-chart > .chart > .canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 160px;
  position: relative;
}
.doughnut-chart > .chart > .canvas > .number {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-default);
}
.doughnut-chart > .chart > .canvas > .body {
  width: 7rem;
  height: 7rem;
}
.doughnut-chart > .chart > .legend {
  width: calc(50% - 5px);
  min-width: 205px;
  display: flex;
  align-items: center;
}
.doughnut-chart > .chart > .legend > .body > .item {
  display: flex;
  align-items: center;
}
.doughnut-chart > .chart > .legend > .body > .item > .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  background: #5969cd;
  border-radius: 4px;
  margin: 0.1rem 0;
  width: 2.5rem;
  height: 1.1rem;
  text-align: center;
  color: #ffffff;
}
.doughnut-chart > .chart > .legend > .body > .item > .name {
  font-size: 0.75rem;
  line-height: 160%;
  color: var(--text-default);
  height: 1.3rem;
  padding: 0.25rem;
  display: inline-block;
  text-overflow: ellipsis;
  width: -moz-max-content;
  width: max-content;
}