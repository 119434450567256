.lcdashboard.invoice-dash .card-frame:hover {
  box-shadow: none;
}


/* .lcdashboard.invoice-dash .card-content-body {
  max-height: calc(100% - 0px);
  min-height: calc(100% - 0px);  
} */


.lcdashboard.invoice-dash .invoice-dash-content {
  max-height: calc(100vh - 180px);
  min-height: calc(100vh - 180px);
}

.lcdashboard:has(.lcdashboard.invoice-dash) .right-sidepanel {
  display: none !important;
}

.lcdashboard:has(.lcdashboard.invoice-dash)>.lcdashboard-cards {
  grid-column-end: 13 !important;
}

.lcdashboard:has(.lcdashboard.invoice-dash) .lcdashboard.invoice-dash .right-sidepanel {
  display: block !important;
}

.lcdashboard .card-frame:has(.lcdashboard.invoice-dash) {
  background-color: transparent !important;
}

.lcdashboard .card-frame:has(.lcdashboard.invoice-dash):hover {
  box-shadow: none !important;
}

.lcdashboard.invoice-dash .right-sidepanel {
  margin-top: -1.4rem;
  margin-right: 0.1rem;
}


.lcdashboard.invoice-dash .right-sidepanel .lc-iconlink:nth-last-child(-n + 1) {
  display: none !important;
}

.lcdashboard.invoice-dash .lcdashboard-cards {
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
}

.lcdashboard.invoice-dash .card-content-body-content {
  display: block;
}

.lcdashboard.invoice-dash .card-frame {

  background-color: #fff;

  box-shadow: none;

}