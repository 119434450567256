.mainContainer {
  margin-bottom: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #e2e5f3;
  padding-bottom: 1rem;
}

.mainFormActive {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #e2e5f3;
  transition: 2s;
}

.mainFormActive input {
  height: 2.2rem;
  border: none;
  border-radius: 5px;
  width: 20rem;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
  padding: 0.3rem;
}

.lc-accordion>.header {
  margin-bottom: 0.6rem !important;
}

.mainFormActive label {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
}

.mainFormNONActive {
  transition: 2s;
}

.mainFormNONActive * {
  display: none;
}

label {
  color: var(--text-default);
}

.accordionDetails {
  flex-direction: column;
  margin: 0.0rem;
}

.accordionDetails>header {
  flex-direction: column;
  margin: 0.0rem;
}

.lc-accordion>.body {
  padding: 0 0 0.6rem 0.8rem !important;
}

.accordionDetails div {
  margin-bottom: 0.2rem;
}

.accordionDetailsTextField {
  margin-bottom: 0.5rem;
}

.accordionDetailsPermission {
  width: 100%;
}

.AccordionSummary {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.loadingOn {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 10px;
}

.loadingOff {
  display: none;
}

.alias {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.aliasIcon>i {
  margin-top: 1rem;
}

.logoProc {
  height: 5rem;
  width: 10rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align: center;
  margin-left: 8.75rem;
}

.slogan {
  font-size: 0.7rem;
  margin-left: 3.75rem;
  margin-bottom: 1rem;
}

.logoLivecloud {
  padding-left: 8.7rem;
}

.buttonConnect {
  width: 13rem;
}

.iconButton {
  margin-right: 1rem;
}

.buttonDiv {
  margin-top: 2rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.buttonBotton {
  width: 29rem;
  height: 3rem;
}

.footerName {
  margin-left: 9.75rem;
  margin-top: 1.5rem;
  font-size: 0.8rem;
}

.copyRight {
  font-size: 0.7rem;
  margin-left: 11.7rem;
  margin-bottom: 1rem;
}

.passwordRequirementText {
  font-size: 0.875rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.licensePanelLabel {
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 5px;
}