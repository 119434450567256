.row.domainsInsights {
    opacity: 0; 
    color: #898da6;
    font-size: 11px;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    padding: 5px;
    transition: opacity .8s ease;
    position: absolute; 
    width: 14%;
}

.iconValueInsights {
    opacity: 0;
    transition: opacity .2s ease;
    padding-right: 10px;

}

.row.domainsInsights.show,
.iconValueInsights.show,
.row.subscriptionInsights.show {
    opacity: 1; 
    transition: opacity .6s ease;
    display: flex;
}

.row.subscriptionInsights {
    opacity: 0; 
    color: #898da6;
    font-size: 12px;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    align-items: flex-start;
    transition: opacity .6s ease;
    position: absolute; 
    width: 30%;
    flex-direction: column;
    height: 30%;
    display: none;
}

.row.subscriptionInsights >.row {
    padding: 1px 5px;
}
.LiveWatchHome {
    min-height: 45% !important;
} 