.lc-checklabelvaluelist {
  position: relative;
  border: 0;
  border-radius: 0.3rem;
  font-size: 14px;
}
.lc-checklabelvaluelist > .filter {
  margin-bottom: 1.75rem;
  margin-right: 1.25rem;
}
.lc-checklabelvaluelist > .filter ::-moz-placeholder {
  font-style: italic;
  color: #8b90a3;
}
.lc-checklabelvaluelist > .filter ::placeholder {
  font-style: italic;
  color: #8b90a3;
}
.lc-checklabelvaluelist > .header {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  padding: 0.3rem 1rem;
}
.lc-checklabelvaluelist > .header .input-toggle,
.lc-checklabelvaluelist > .header .input-checkbox {
  margin-right: 25px;
}
.lc-checklabelvaluelist > .header .title {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--text-default);
}
.lc-checklabelvaluelist > .list {
  min-height: calc(100vh - 400px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.lc-checklabelvaluelist > .list > .item {
  display: flex;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  background-color: #fff;
  margin-bottom: 3px;
  color: #0580ce;
}
.lc-checklabelvaluelist > .list > .item .input-toggle,
.lc-checklabelvaluelist > .list > .item .input-checkbox {
  margin-right: 25px;
}
.lc-checklabelvaluelist > .list > .item:nth-child(even) {
  background-color: #fafdff;
}
.lc-checklabelvaluelist > .list > .item:hover {
  background-color: #eee;
}
.lc-checklabelvaluelist > .list > .item > label {
  font-size: 1rem;
}