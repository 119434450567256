.lc-vinsubscriptions {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  flex-flow: row wrap;
  position: relative;
}
.lc-vinsubscriptions > .lc-segment {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
  padding-bottom: 22px;
}
.lc-vinsubscriptions > .lc-segment > .title {
  font-weight: 200;
  font-size: 1.2rem;
  color: var(--text-default);
  line-height: 1.9375rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.lc-vinsubscriptions > .lc-segment > .title .cost {
  font-size: 1rem;
  font-weight: 500;
}
.lc-vinsubscriptions > .lc-segment > .secondary {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 160%;
  color: var(--text-default);
}
.lc-vinsubscriptions > .lc-segment > .secondary:not(:last-child()) {
  border-bottom: 1px solid #cccccf;
}
.lc-vinsubscriptions > .lc-segment > .secondary > .functions {
  display: flex;
}
.lc-vinsubscriptions > .lc-segment > .list > .item {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 160%;
  color: #8b90a3;
}
.form {
  margin-bottom: 1.2rem
}