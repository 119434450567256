.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Escurecendo o fundo para dar destaque ao modal */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: #ffffff; /* Definindo o fundo do modal como branco */
    color: #000000; /* Definindo a cor do texto como preto */
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.authentication-input-field {
    min-width: 70%;
}

.authentication-input-field.lc-input {
    min-width: 80%;
}

h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
}

.authentication-input {
    place-content: space-between;
}

.pp-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

    .pp-button:hover {
        background-color: #0056b3;
    }
