.allAI>.contentAI , .contentAI {
    background-color: #ECF0FD;
    width: 100%;
    max-height: 0;
    /* visibility: hidden; */
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 3px;    
    padding: 0 15px;
    border: none;

}

.allAI>.contentAI.open, .contentAI.open {
    max-height: 137px;
    max-height: 250px;
    min-width: 300px;
    /* visibility: visible; */
    padding: 5px 10px;
    overflow: visible;
    transition: max-height 0.5s ease-in-out;

}

.allAI>.contentAI.open.borderAI {
    border-bottom: 2px solid;
    border-radius: 3px;    
    animation: borderAnimation 6s infinite ease-in-out;

}


.allAI>.contentAI .AIText,.contentAI .AiText {
    padding: 0px 10px;
    resize: none;
    font-size: 14px;
    color: #000;
}

.allAI>.contentAI .title-sugestao ,.contentAI .title-sugestao {

    margin: 10px 0;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    padding: 0px 10px;
    line-height: 13.31px;
}

.action-wrapper {
    /* margin-top: 10px; */
    display: flex;
    justify-content: flex-start;
    float: left;
    flex-direction: row;
    gap: 6px;
    padding: 0px 5px ;
}

.contentAI > .action-wrapper {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    /* float: left; */
    flex-direction: row;
    /* margin-top: 5px; */
}

.contentAI > .action-wrapper > .row > .lciconlink:hover{
    background-color: #fff !important;
}
.contentAI > .action-wrapper > .lc-button {
    font-size: 12px;
    width: 135px;
    padding: 5px;
    height: 40px;

}
.originalText.disabled {
    background-color: #ECF0FD;
    border: none;
    color: #000;
    min-height: 40px;
    /* cursor: not-allowed; */
}

@keyframes borderAnimation {
    0%, 50%, 100% {
        border-color: #00FF00; /* Verde */
      }
    25% , 75%{
        border-color: #00FFFF; /* Azul */
      }
}
@keyframes openFromBottom {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
@media screen and (max-width: 1900px) {
    .dialog-modal>.allAI>.content {
        background-color: #e2e6f3;
        max-width: 1000px;
    }
}

@media screen and (max-width: 2800px) {
    .dialog-modal>.allAI>.content {
        max-width: 1500px;
    }
}

@media screen and (max-width: 600px) {
    .allAI:not(.open)  {
        display: none;
    }
    .allAI.open {
        display: block;
        height: auto;
        /* min-height: 170px; */
    }
    .allAI>.contentAI.open {
        width: auto;
        margin-top: 0px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-width: 340px;
    }
    .allAI>.contentAI:not(.open)  {
        height: 0px;
    }
    .contentAI >  .action-wrapper {
        padding: 0px;
        align-items: center;
    }
    .contentAI >  .action-wrapper > .lc-button {
        width: 180px;
    }
}