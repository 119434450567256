.otimizacao .pp-expand2 > .header:not(.height) > .functions {
  width: 50%;
}

.spanExpandLeft {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

.spanExpandRight {
  display: flex;  
  justify-content: flex-end;
  font-size: 14px;
}

.recomendationHeader {
  font-size: 14px;
  font-weight: bold;
  padding: 12px 24px;
  font-family: "Ubuntu", sans-serif;
  line-height: 16px;
  letter-spacing: 0em;
  color:var(--text-default);
}

.recomendation {
  margin-left: 0%;
  width: 17%;
}

.quantity {
  margin-left: 8%;
}

.economy {
  margin-left: 24px;
  width: 18%;
}

.definitionText {
  color: #F29100;
  margin: 9px 0px;
  text-align: left;
  font-size: 14px;
}

.resourceName {
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  position: relative;
}
.resourceName .pp-tooltip .body.left {
  justify-content: flex-start;
  z-index: 9999;
  height: 80px;
}

.capitalizeDates {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-feature-settings: "tnum" on, "Inum" on;
  letter-spacing: -0.1em;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
}