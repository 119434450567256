/* ExpandingTextarea.css */
.textarea-container-parent {}

.textarea-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--line-outline);
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #fff;
    transition: border-color 0.3s, box-shadow 0.3s;
    flex-grow: 1;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    /* Light */
}

.textarea-container.focused {
    border-color: var(--color-secondary-lime);
    box-shadow: 0 0 0 1px #DFFF00;
}

.textarea-container .textarea {
    line-height: 24px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    font-size: 14px;
    color: var(--text-default);
    flex-grow: 1;    
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    margin-bottom: 4px;
}

.send-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 6px 8px;
    margin-left: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;

}

.send-button.focused {
    background-color: var(--color-secondary-lime);
}

.send-button-icon {
    transition: color 0.3s;
}

.send-button-icon.focused {
    color: var(--text-default);
}

.send-button-icon.unfocused {
    color: #cccccf;
}