.input-toggle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0.65rem;
  margin-top: 0.15rem;
}

.input-toggle>.box {
  width: 32px;
  height: 16px;
  padding: 3px;
  border-radius: 10px;
  background-color: #eee;
}

.input-toggle>.box>.dot {
  width: 10px;
  height: 10px;
  border-radius: 7px;
  background-color: var(--text-light);
  transition: all 0.2s ease;
}

.input-toggle.toggled>.box {
  background-color: var(--color-secondary-lime) !important;
}

.input-toggle.toggled>.box>.dot {
  margin-left: 16px;
}

.input-toggle>.title {
  margin-left: 7px;
  margin-bottom: 3px;
  font-size: 0.835rem;
  color: #2E1956;
  font-family: "Ubuntu", sans-serif;
}