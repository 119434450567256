.lc-dropdown {
  display: inline-block;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.lc-dropdown > .element {
  cursor: pointer;
}
.lc-dropdown > .body {
  width: 0;
  height: 0;
  position: absolute;
  overflow: visible;
  z-index: 6000;
}
.lc-dropdown > .body > .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: -moz-max-content;
  width: max-content;
  min-width: 180px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border: 1px solid #cccccf;
  padding: 12px;
  box-shadow: 0px 0px 18px rgba(58, 79, 135, 0.3);
}
.lc-dropdown > .body > .content > .item,
.lc-dropdown > .body > .content > div .item {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--text-default) !important;
  cursor: pointer;
}
.lc-dropdown > .body > .content > .item.disabled,
.lc-dropdown > .body > .content > div .item.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.lc-dropdown > .body > .content > .item:hover,
.lc-dropdown > .body > .content > div .item:hover {
  background-color: #eee;
}
.lc-dropdown > .body > .content > hr {
  margin: 8px 10px;
}

.lc-dropdown > .body.left {
  left: 0;
}
.lc-dropdown > .body.center {
  left: 50%;
}
.lc-dropdown > .body.right {
  right: 0;
}