/* .assistant-dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  header {
    background-color: #003366;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .content {
    display: flex;
    flex: 1;
  }
  
  aside {
    width: 20%;
    background-color: #fff;
    padding: 10px;
    border-right: 1px solid #ddd;
  }
  
  aside button {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #003366;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  aside ul {
    list-style: none;
    padding: 0;
  }
  
  aside li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  aside li:hover {
    background-color: #ddd;
  }
  
  main {
    flex: 1;
    padding: 20px;
  }
  
  .assistant-details {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  
  .assistant-details h2 {
    margin-top: 0;
  }
  
  .assistant-details button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #003366;
    color: white;
    border: none;
    cursor: pointer;
  }
   */
   /* .listItem {
    height: 40px;
  
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .listItemText {
    height: 40px;
 
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .assistant-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  .assistant-list-item:hover {
    background-color: #f9f9f9;
  } */

  

  /* .assistant-list-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  .assistant-list-item:hover {
    background-color: #f9f9f9;
  }
  
  .assistant-list-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .assistant-list-actions Button {
    margin: 4px 0;
  }*/

  .list-item {
    max-height: 150px;
    overflow: hidden;
    position: relative;
  }
  
  .list-item:hover {
    overflow: auto;
  }
  
  .listItemText {
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .list-item:hover .listItemText {
    white-space: normal;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .icon-button {
    cursor: pointer;
    margin-bottom: 8px;
  }
  .icon-button {
    cursor: pointer;
  } 
  .icon-button .MuiIconButton-root {
    font-size: 20px; 
  } 
  