.dashboard-saas .graph-card {
  background-color: #FFF;
  height: 250px;
  width: 350px;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}
.dashboard-saas .graph-card .graph-card-body .graph-card-title {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  padding-bottom: 0rem;
}
.dashboard-saas .graph-card .graph-card-body .graph-card-subtitle {
  padding-top: 0rem;
}
.dashboard-saas .graph-card .graph-card-body .graph-card-subtitle span {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 160%;
  color: #434a63;
}
.dashboard-saas .graph-card .graph-card-body .graph-card-subtitle canvas {
  padding-top: 1rem;
}
.dashboard-saas .button {
  width: 7rem;
}