 .cardSubTitle {
 	margin: auto;
 	font-size: 1.1vw;
 	font-weight: 400;
 	font-family: 'Ubuntu';
 	color: var(--color-primary-blue) !important;
 }

 .licenseSummaryLine {
 	font-size: 0.8vw;
 	margin-top: 6px;
 }

 .licenseCount {
 	font-size: 16px;
 	font-weight: 600;
 }

 .globalSummaryLine {
 	font-size: 18px;
 	font-weight: 600;
 	margin-top: 6px;
 }

 .followUpFlagsContainer {
 	display: flex;
 	justify-content: space-between;
 	padding: 20px 20px;
 }

 .flagItem {
 	display: flex;
 	flex-direction: column;
 	align-items: center;
 	text-align: center;
 }

 .flagItem img {
 	width: 64px;
 	height: 48px;
 	margin-bottom: 10px;
 }

 .summaryLineByCountry {
 	font-size: 12px;
 	margin-top: 6px;
 	font-family: 'Ubuntu';
 	font-weight: 500;
 }

 .GAP {
 	color: #428042;
 	width: 50%;
 }

 .summaryContainer {
 	max-height: 100%;
 	overflow-y: hidden;
 }

 .executedByCompanyContainer {
 	height: 100%;
 }

 @media screen and (max-height: 649px) {
 	.summaryContainer {
 		max-height: 100px;
 		overflow-y: auto;
 	}

 	.executedByCompanyContainer {
 		max-height: 160px;
 		max-width: 110px;
 		margin: 0 0 80px 30px;
 	}
 }