.add-alarm-row {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the input fields and button */
    margin-bottom: 10px; /* Space below the add row */
}

.alarms-list {
    list-style-type: none;
    padding: 0;
}

.alarm-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 0; /* Reduced padding for a more compact look */
    font-size: 12px; /* Smaller font size */
}

.edit-alarm,
.view-alarm {
    display: flex;
    align-items: center;
    width: 100%;
}

.actions {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    gap: 10px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit; /* Inherit color from parent element */
}

    .icon-button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .icon-button svg {
        width: 16px; /* Smaller icon size */
        height: 16px; /* Smaller icon size */
    }

.view-alarm span {
    flex-grow: 1;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px; /* Smaller font size */
}
