.news-article {
    font-family: 'Ubuntu';
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;

    line-height: 1.6;
    border-radius: 4px;
}

.news-header,
.news-footer {
    text-align: center;
    margin-bottom: 20px;
}

.news-title {
    color: #333;
    margin-bottom: 5px;
}

.news-date {
    color: #888;
    font-size: 0.85em;
}
.news-introduction {
    color: #666;
}
.news-content {
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.news-category {
    margin-bottom: 20px;
}

.news-category-title {
    color: #444;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.news-category-list {
    list-style: none;
    padding-left: 0;
}

.news-category-item {
    background-color: #f9f9f9;
    margin-bottom: 5px;
    padding: 10px;
    border-left: 3px solid #0078d4; /* A color that suits the theme */
    border-left: 3px solid #789; /* A color that suits the theme */
    border-radius: 2px;
}

.news-additional-info,
.news-objective,
.news-contact,
.news-thanks,
.news-sign-off {
    color: #666;
    margin-top: 20px;
    font-size: 0.95em;
}
