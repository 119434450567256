.page-agendamentos .MuiBadge-root {
  margin: 0px 15px;

}
.page-agendamentos .MuiBadge-root .MuiBadge-colorPrimary {
  background-color: var(--color-secondary-mint);
  color: var(--text-default);
}
.page-agendamentos .customBadgeWeek span {
  background-color: var(--background-neutral);
  color: var(--text-light);
  word-break: keep-all !important;
  font-size: 10px;
  font-family: "Ubuntu", sans-serif;
  padding: 8px;
  border-left: 1px solid #fff;
}