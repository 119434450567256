.iconvaluecontent {
    min-width: 100%;
    display: flex;
    flex-direction: row;
}

.truncate-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
    max-width: 100%;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.iconvaluecontent .description {
    color: var(--text-light);
    text-align: right;
    font-family: "Ubuntu", sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 400;    
}

    .iconvaluecontent .description.home {
        font-size: 1.7vh;
    }

.iconvaluecontent.grey {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.small-card .iconvaluecontent.grey {
    gap: 4px;
}

.iconvaluecontent.grey .data {
    color: var(--text-default);
}

.small-card .iconvaluecontent .icone {
    position: relative;
    top: -45%;
    width: 2.5vh;
    height: 2.5vh;
}

    .small-card .iconvaluecontent .icone i {
        font-size: 2.5vh !important;
    }

.small-card .iconvaluecontent .data div {
    font-size: 1.1vw !important;
}
.small-card .iconvaluecontent.bigNumber .data div {
    font-size: .8vw !important;
}

.iconvaluecontent .data {
    display: flex;
    justify-content: end;
}

    .iconvaluecontent .data .right {
        text-align: right;
    }

.iconvaluecontent.consumo .data div {
    color: var(--text-default) !important;
    font-family: "Ubuntu", sans-serif;
    font-size: 4.5vh !important;
    font-style: normal;
    font-weight: 400;
    text-align: left;
}

.iconvaluecontent.consumo .data {
    justify-content: start;
}


.iconvaluecontent.office-services {
    justify-content: space-evenly !important;
    gap:6px;
}

.iconvaluecontent.grey.office-services {
    justify-content: start !important;    
}

.small-description {
    font-size: 1.7vh;
    color: var(--text-light);
    text-align: right;
    font-family: "Ubuntu", sans-serif;    
    font-style: normal;
    font-weight: 400;    
}

.balonCard {
    position: relative;
    width: 10px;
    height: 10px;
    background-color: #fff;
    rotate: 45deg; 
    left: 50%; 
    bottom: -5%;
    transform: translateX(-50%);
}
.balonCard.withHeaderButton{
    bottom: 3%;
}

.card-active:has(.balonCard) {
    box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, .25) !important;
}

@media (min-width: 1600px) {
    .balonCard{
        bottom: -3%;
    }
    .balonCard.withHeaderButton{
        bottom: 1%;
    }
    
}