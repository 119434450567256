.row > .cells > .cell > .pp-tooltip {
  width: 100%;
}

.cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
}

.sub {
  width: 100px;
}