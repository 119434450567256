.homegrid {
    margin: 0;
}

    .homegrid > .groups {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto;
        gap: 0.75rem;        
        height:calc(100vh - 68px);
    }

        .homegrid > .groups > .group {
            background: #ffffff;
            border-radius: 0.25rem;
            padding: .5rem 1rem .5rem 1rem;
            display: flex;
            flex-direction: row;
            align-content: flex-start;
            gap: 0.75rem;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-shrink: 0;
            box-shadow: rgba(58, 79, 135, 0.06) 4px 4px 18px 0px;
        }

            .homegrid > .groups > .group:hover {
                box-shadow: rgba(58, 79, 135, 0.3) 4px 4px 18px 0px;
            }

                .homegrid > .groups > .group:hover .imagem-gtm {
                    box-shadow: rgba(58, 79, 135, 0.2) 4px 4px 18px 0px;
                }

            .homegrid > .groups > .group.group_0 {
                grid-area: 1 / 1 / 2 / 2;
            }

            .homegrid > .groups > .group.group_1 {
                grid-area: 1 / 2 / 2 / 3;
            }

            .homegrid > .groups > .group.group_2 {
                grid-area: 1 / 3 / 2 / 4;
            }

            .homegrid > .groups > .group.group_3 {
                grid-area: 2 / 1 / 3 / 2;
            }

            .homegrid > .groups > .group.group_4 {
                grid-area: 2 / 2 / 3 / 3;
            }

            .homegrid > .groups > .group.group_5 {
                grid-area: 2 / 3 / 3 / 4;
            }


            .homegrid > .groups > .group > .header {
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;
                justify-content: flex-start;
                flex-shrink: 0;
            }


                .homegrid > .groups > .group > .header > .imagem-gtm {
                    border-radius: 50%;
                    width: 64px;
                    height: 64px;
                }

            .homegrid > .groups > .group.group_0 > .header > .imagem-gtm {
                background-image: url("../../assets/images/group_0.png");
            }

            .homegrid > .groups > .group.group_1 > .header > .imagem-gtm {
                background-image: url("../../assets/images/group_1.png");
            }

            .homegrid > .groups > .group.group_2 > .header > .imagem-gtm {
                background-image: url("../../assets/images/group_2.png");
            }

            .homegrid > .groups > .group.group_3 > .header > .imagem-gtm {
                background-image: url("../../assets/images/group_3.png");
            }

            .homegrid > .groups > .group.group_4 > .header > .imagem-gtm {
                background-image: url("../../assets/images/group_4.png");
            }

            .homegrid > .groups > .group.group_5 > .header > .imagem-gtm {
                background-image: url("../../assets/images/group_5.png");
            }


            .homegrid > .groups > .group > .header > .title {
                color: #2E1956;
                font: 500 1rem/160% 'Ubuntu', sans-serif;
                letter-spacing: -0.04rem;
            }

                .homegrid > .groups > .group > .header > .title > small {
                    font-size: 0.8rem;
                }

            .homegrid > .groups > .group > .items {
                display: flex;
                flex-direction: column;
                gap: 0px;
                align-items: flex-start;
                justify-content: flex-start;
                flex-shrink: 0;
                width: 100%;
            }

                .homegrid > .groups > .group > .items > .item {
                    font-size: 0.875rem;
                    font-family: "Ubuntu", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                    display: flex;
                    padding: 0.2rem 0.625rem;
                    align-items: baseline;
                    gap: 5px;
                    align-self: stretch;
                    border-radius: 0.3125rem;
                    color: #2B304F;
                    background: #fff;
                    transition: 0.3s;
                    border: 1px solid transparent;
                }

                    .homegrid > .groups > .group > .items > .item:hover {
                        background-color: #ddd;
                        color: #fff;
                    }

            .homegrid > .groups > .group.group_0 > .items > .item:hover {
                background: #5F5DAD;
            }

            .homegrid > .groups > .group.group_1 > .items > .item:hover {
                background: #0055A8;
            }

            .homegrid > .groups > .group.group_2 > .items > .item:hover {
                background: #173192;
            }

            .homegrid > .groups > .group.group_3 > .items > .item:hover {
                background: #16C2F3;
            }

            .homegrid > .groups > .group.group_4 > .items > .item:hover {
                background: #6E2D8E;
            }

            .homegrid > .groups > .group.group_5 > .items > .item:hover {
                background: #A7CD37;
            }



            .homegrid > .groups > .group > .items > .item.blocked {
                color: #b5b7c1;
                background: #fff;
                transition: 0.3s;                
            }

                .homegrid > .groups > .group > .items > .item.blocked:hover {
                    background: #E2E6F3;                    
                }


            .homegrid > .groups > .group.group_0 > .items > .item.blocked:hover {
                background: #EFEFF7;                
                border-color: #5F5DAD;
            }

            .homegrid > .groups > .group.group_1 > .items > .item.blocked:hover {
                background: #E6EEF6;
                border-color:#0055A8
            }

            .homegrid > .groups > .group.group_2 > .items > .item.blocked:hover {
                background: #e9edfa;
                border-color: #173192;
            }

            .homegrid > .groups > .group.group_3 > .items > .item.blocked:hover {
                background: #E8F9FE;
                border-color: #16C2F3;
            }

            .homegrid > .groups > .group.group_4 > .items > .item.blocked:hover {
                background: #F1EAF4;
                border-color: #6E2D8E;
            }

            .homegrid > .groups > .group.group_5 > .items > .item.blocked:hover {
                background: #F6FAEB;
                border-color: #A7CD37;
            }

            .homegrid > .groups > .group > .items > .item small {
                color: #8B90A3;
                font-size: 0.75rem;
                font-family: "Ubuntu", sans-serif;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                transition: 0.3s;
            }

            .homegrid > .groups > .group > .items > .item.blocked small, .homegrid > .groups > .group > .items > .item.blocked:hover small {
                color: #b5b7c1;
            }

            .homegrid > .groups > .group > .items > .item:hover small {
                color: #fff;
            }
