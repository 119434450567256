.graph-container {
  color: var(--text-default);
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.date-info {
  color: var(--text-default);
  font-size: 0.7rem;
}

.bkpsilverresume .doughnut-chart > .chart {
  flex-wrap: unset;
}
.bkpsilverresume .doughnut-chart > .chart > .legend {
  min-width: unset;
}