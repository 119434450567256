.progress-bar-container {
    width: 100%;
    opacity: 0.9;
    padding: 0px 8px;
}
.progress-bar {
    height: 18px;
    background: linear-gradient(90deg, #E64646 0%, #F3C944 50%, #75f945 100%);
    text-align: end;
    padding-right: 5px;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    font-size: 12px;
    width: 100%;
    transition: width 0.3s ease-in-out;
    opacity: 0.9;
}

.slider-thumb {
    width: 10px;
    height: 30px;
    background: #6c63ff;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    margin-top: -25px;
    border: 1.5px solid #fff;
    background-color: #6200ea; 
}

.percentage {
    width: 35%;
    margin-top: 3px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    color: #6c63ff;
    position: relative;

}

.percentage.normal {
    font-size: 14px;
}
.percentage.small {
    font-size: 12px;
}

.percentage.noLabel {
    width: 10%;
}

.progress-bar:hover {
    opacity: 1;
}
.progress-bar-container:hover {
    opacity: 1;
}

.dividedInfo {
    min-height: 50% !important;
}

.securityIconValue{
    margin-top:  -30px;
}
.progress-bar-container {
    padding: 0px 10px;
}

.percentage > .percentLabel {
    width: 100%;
    font-size: 10px;
    text-align: center;
    color: #464a65;
}