.titulos {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: var(--text-default);
  flex: none;
  order: 0;
  flex-grow: 0;
  height: 26px;
  position: static;
  left: 0px;
  top: 0px;
  margin: 10px 0px;
}

.rdButton {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.MuiFormControlLabel-label {
  font-family: "Ubuntu" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: var(--color-secondary-lime) !important;
}
.inputTextField > .MuiInputBase-root.MuiOutlinedInput-root, 
.condValue > .MuiInputBase-root.MuiOutlinedInput-root
 {
  height: 28px !important;
  margin-top: 5px;
}
