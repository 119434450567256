i.lcfi {
  display: inline-block;
  width: 36px;
  height: 36px;
}
i.lcfi.lcfi-ai {
  content: url("./imgs/ai.png");
}
i.lcfi.lcfi-avi {
  content: url("./imgs/avi.png");
}
i.lcfi.lcfi-css {
  content: url("./imgs/css.png");
}
i.lcfi.lcfi-csv {
  content: url("./imgs/csv.png");
}
i.lcfi.lcfi-dbf {
  content: url("./imgs/dbf.png");
}
i.lcfi.lcfi-doc {
  content: url("./imgs/doc.png");
}
i.lcfi.lcfi-dwg {
  content: url("./imgs/dwg.png");
}
i.lcfi.lcfi-exe {
  content: url("./imgs/exe.png");
}
i.lcfi.lcfi-fla {
  content: url("./imgs/fla.png");
}
i.lcfi.lcfi-html {
  content: url("./imgs/html.png");
}
i.lcfi.lcfi-iso {
  content: url("./imgs/iso.png");
}
i.lcfi.lcfi-jpg {
  content: url("./imgs/jpg.png");
}
i.lcfi.lcfi-js {
  content: url("./imgs/js.png");
}
i.lcfi.lcfi-json {
  content: url("./imgs/json.png");
}
i.lcfi.lcfi-mp3 {
  content: url("./imgs/mp3.png");
}
i.lcfi.lcfi-mp4 {
  content: url("./imgs/mp4.png");
}
i.lcfi.lcfi-pdf {
  content: url("./imgs/pdf.png");
}
i.lcfi.lcfi-png {
  content: url("./imgs/png.png");
}
i.lcfi.lcfi-ppt {
  content: url("./imgs/ppt.png");
}
i.lcfi.lcfi-psd {
  content: url("./imgs/psd.png");
}
i.lcfi.lcfi-rtf {
  content: url("./imgs/rtf.png");
}
i.lcfi.lcfi-svg {
  content: url("./imgs/svg.png");
}
i.lcfi.lcfi-txt {
  content: url("./imgs/txt.png");
}
i.lcfi.lcfi-xls {
  content: url("./imgs/xls.png");
}
i.lcfi.lcfi-xml {
  content: url("./imgs/xml.png");
}
i.lcfi.lcfi-zip {
  content: url("./imgs/zip.png");
}
i.lcfi.lcfi-05x {
  width: 20px;
  height: 20px;
}
i.lcfi.lcfi-075x {
  width: 26px;
  height: 26px;
}
i.lcfi.lcfi-2x {
  width: 42px;
  height: 42px;
}
i.lcfi.lcfi-3x {
  width: 56px;
  height: 56px;
}
i.lcfi.lcfi-4x {
  width: 62px;
  height: 62px;
}
i.lcfi.lcfi-5x {
  width: 74px;
  height: 74px;
}