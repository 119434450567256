.gauge-chart {
  min-height: 170px;
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.8rem;
  border-radius: 1rem;
  position: relative;
  box-shadow: 16px 16px 16px rgba(58, 79, 135, 0.06)
}
.gauge-chart.link:hover {
  background-color: #ddd;
}
.gauge-chart > .header {
  display: flex;
  justify-content: space-between;
}
.gauge-chart > .header > .title {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 0.9rem;
  color: #8b90a3;
}
.gauge-chart > .chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: calc(100% - 20px);
  pointer-events: none;
}
.gauge-chart > .chart > .body {
  position: relative;
  display: flex;
  justify-content: center;
  width: 130px;
  height: 130px;
  align-self: center;
}
.gauge-chart > .chart > .body > .lci {
  position: absolute;
  top: 50px;
  font-size: 90px;
  color: #777;
  transition: all 0.5s;
}
.gauge-chart > .chart > .number {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--text-default);
  text-align: center;
  margin-top: -20px;
}
.gauge-chart > .chart > .label {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-default);
  text-align: center;
}