.marketing {
  height: calc(100vh - 120px);
  margin: 0 15%;
}
.marketing .preview {
  z-index: -1;
  height: 200px;
  overflow-y: visible;
}
.marketing .preview > .image {
  width: 100%;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
}
.marketing h1,
.marketing h2,
.marketing h3,
.marketing h4 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  color: var(--primary);
}
.marketing h1 {
  padding: 0 150px;
  font-size: 2rem;
  text-align: center;
}
.marketing ul {
  margin: 20px 0;
}
.marketing p {
  margin: 20px 0;
}
.marketing p::first-letter {
  margin-left: 20px;
}
.marketing .block {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.marketing .block > span {
  cursor: pointer;
  background-color: var(--primary);
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
}
.marketing > .modal-marketing {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: none;
}
.marketing > .modal-marketing.visible {
  display: block;
}
.marketing > .modal-marketing > .shadow {
  height: 50vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6274509804);
}
.marketing > .modal-marketing > .content {
  height: 50vh;
  width: 100vw;
  padding: 20px 15%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.marketing > .modal-marketing > .content > .header {
  display: flex;
  justify-content: flex-end;
}
.marketing > .modal-marketing > .content > .body {
  height: 50vh;
  overflow: auto;
}
.marketing > .modal-marketing > .content > .body > h1,
.marketing > .modal-marketing > .content > .body h2,
.marketing > .modal-marketing > .content > .body h3 {
  color: rgb(66, 155, 214);
}
.marketing > .modal-marketing > .content > .body > p {
  font-size: 1.2rem;
  color: #fff;
}