.detailList {
  height: calc(100vh - 400px);
  width: 97%;
  overflow: auto;
}

.providerToolbar {
  display: flex;
  position: relative;
  align-items: right;
  justify-content: right;
}

.subscriptionForm {
  display: contents !important;
}

.subscriptionForm .pp-multiselect {
  padding-right: 5px !important;
}

#searchSubscriptions {
  margin-top: 20px;
  margin-left: -10px;
}