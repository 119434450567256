.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fundo escuro para destacar o modal */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: #ffffff; /* Fundo branco para o modal */
    color: #000000; /* Texto em preto */
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
}

.radio-group {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

    .radio-group label {
        display: flex;
        align-items: center;
        gap: 8px;
    }

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pp-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

    .pp-button:hover {
        background-color: #0056b3;
    }
