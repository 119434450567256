.detailsPlan {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
}

.headerPlanDetails {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 24px;
    padding: 5px 16px 0 0;
}

.rowPlanDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: .6rem 0;
    gap: 1.3rem;
}

.inicialInfo {
    display: flex;
    margin-bottom: .5rem;
}

.detailsName {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailsName h4 {
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    color: #2B304F;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.bagdeStatusPlan {
    margin-left: 5px;
    padding: 2px 8px;
    color: white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
}

.labelGray {
    color: #a4a9b9;
    font-size: 12px;
}

.labelGray.textSmall {
    font-size: 10px;
}

.resource_scope {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: end;
    justify-content: space-around;
    font-size: 12px;
}

.resource_scope p {
    /* color: #3ebb3f; */
    font-weight: bold;
}

.useDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divDetails {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}

.divDetails>.row>p,
.rowPlanDetails>.row>p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 128px;
    font-size: 12px;
}

.percente {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalPercente {
    background-color: #8b90a3;
    color: #fff;
    width: 100%;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 12px;

}

.totalPercente p {
    position: absolute;
    width: 100%;
    top: 50%;
    /* Posiciona o texto no meio verticalmente */
    transform: translateY(-50%);
}

.totalPercente>.usePercente {
    background-color: var(--text-default);
    height: 24px;
}

.usedAndReservedInfo {
    font-size: 10px;
    display: flex;
    flex-direction: column;
}

.usedAndReservedInfo .labelGray {
    font-size: 10px;
}

.userandreserveInfo p {
    flex-wrap: nowrap;
    width: 100%;
}

.userandreserveInfo span {
    color: #a4a9b9;
    font-size: .8rem;
}

.graphDetails {
    width: 50%;
    /* box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);     */
}

.titleLink {
    color: #00aee1;
    text-decoration: underline;
    cursor: pointer;
}

.detailSavingsPlanOrReserve {
    background-color: #F1F5FB;
    border-radius: 4px;
    padding: 12px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subTitlePlan {
    font-size: 12px;
    color: #2B304F;
}