body {
    background-color: var(--background-lightest);
    font-family: "Ubuntu", sans-serif !important;
}

.container>.topbar>.right::after {
    background: none !important;
}

body .container {
    max-width: 100%;
    background-color: var(--background-lightest);
}

body .container>.topbar {

    background: linear-gradient(90deg,
            var(--color-primary-purple-dark) 40%,
            var(--color-primary-purple) 100%);



}

body .container>.topbar>.left>.lc-tooltip .item.notifications,
body .container>.topbar .center>.lc-tooltip .item.notifications,
body .container>.topbar .right>.lc-tooltip .item.notifications {
    position: relative;
}

body .container>.topbar>.left>.lc-tooltip .item.notifications .badge,
body .container>.topbar .center>.lc-tooltip .item.notifications .badge,
body .container>.topbar .right>.lc-tooltip .item.notifications .badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 3px;
    right: 3px;
    background-color: #fff;
    color: #000;
    font-size: 10px;
    padding: 2px;
    border-radius: 8px;
    min-width: 20px;
    color: #fff;
    background-color: #4ebaff;
}

body .container>.topbar>.left>.lc-tooltip .item.notifications .badge.success,
body .container>.topbar .center>.lc-tooltip .item.notifications .badge.success,
body .container>.topbar .right>.lc-tooltip .item.notifications .badge.success {
    background-color: #a5bf3e;
}

body .container>.topbar>.left>.lc-tooltip .item.notifications .badge.warning,
body .container>.topbar .center>.lc-tooltip .item.notifications .badge.warning,
body .container>.topbar .right>.lc-tooltip .item.notifications .badge.warning {
    background-color: var(--color-secondary-lime);
    color: var(--text-default);
}

body .container>.topbar>.left>.lc-tooltip .item.notifications .badge.danger,
body .container>.topbar .center>.lc-tooltip .item.notifications .badge.danger,
body .container>.topbar .right>.lc-tooltip .item.notifications .badge.danger {
    background-color: #e64646;
}

body .container>.topbar>.left .lc-tooltip>.item,
body .container>.topbar .center>.lc-tooltip>.item,
body .container>.topbar .right>.lc-tooltip>.item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    cursor: pointer;
    color: #fff;
}

body .container>.topbar>.left .lc-tooltip>.item>i,
body .container>.topbar .center>.lc-tooltip>.item>i,
body .container>.topbar .right>.lc-tooltip>.item>i {
    font-size: 24px;
}

body .container>.topbar>.left .lc-tooltip>.item:hover,
body .container>.topbar>.left>.lc-tooltip>.item.active,
body .container>.topbar .center>.lc-tooltip>.item:hover,
body .container>.topbar .center>.lc-tooltip>.item.active {
    background-color: rgba(0, 0, 0, 0.4);
}

body .container>.topbar .right>.lc-tooltip>.item:hover,
body .container>.topbar .right>.lc-tooltip>.item.active {
    background-color: rgba(46, 25, 86, 0.4);
}

body .container>.topbar>.left .lc-tooltip>.item>.avatar,
body .container>.topbar .center>.lc-tooltip>.item>.avatar,
body .container>.topbar .right>.lc-tooltip>.item>.avatar {
    width: 24px;
    height: 24px;
    border: 1px solid #fff;
    border-radius: 14px;
}

body .container>.topbar>.left>.items {
    display: flex;
    gap: 0px;
}

body .container>.topbar>.left>.items>.division {
    width: 3px;
    height: 22px;
    border-radius: 2px;
    background-color: #ccc;
    margin-left: 8px;
    margin-right: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

body .container>.topbar>.left>.items>.group {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 150px;
    font-size: 0.8rem;
    padding-right: 10px;
}

body .container>.topbar>.left .items .lc-tooltip .item .title {
    margin-left: 0;
}

body .container>.topbar>.left .items .gtm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    min-width: 52px;
    margin-top: 8px;
    border-radius: 10px 10px 0 0;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    color: var(--text-dark);
    position: relative;
    background-color: var(--background-white);
    margin-left: 20px;
    margin-right: 20px;
}

body .container>.topbar>.left .items .gtm.secops {
    background-color: #f1f5fb;

}

body .container>.topbar>.left .items .gtm .title {
    font-size: 14px;
}

body .container>.topbar>.left .items .gtm::after,
body .container>.topbar>.left .items .gtm::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 20px;
    background-color: var(--color-primary-purple-dark);
    border-radius: 50%;
}

body .container>.topbar>.left .items .gtm::after {
    left: -20px;
    box-shadow: 10px 10px #fff;
}

body .container>.topbar>.left .items .gtm::before {
    right: -20px;
    box-shadow: -10px 10px #fff;
}

body .container>.topbar>.left .items .gtm.secops::after {
    box-shadow: 10px 10px #f1f5fb;
}

body .container>.topbar>.left .items .gtm.secops::before {
    box-shadow: -10px 10px #f1f5fb;
}


body .container>.topbar>.left .items .gtm>i {
    font-size: 24px;
    text-align: center;
    font-weight: 100;
}

body .container>.topbar>.center {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

body .container>.topbar>.center>.logo {
    height: 20px;
    margin: 0 20px;
}

body .container>.topbar>.right .changearea {
    padding-left: 20px;
    margin-right: 15px;
    width: 250px;
    display: flex;
    justify-content: end;
    align-items: center;
}

body .container>.topbar>.right .changearea .lastupdate {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: 400;
    font-size: 11px;
    color: var(--text-white);
    opacity: 0.5;
    gap: 2px;
}

body .container>.topbar>.right .changearea .notification {
    height: 50px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 10px;
    text-align: right;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
}

body .container>.topbar>.right .changearea .notification>span {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

body .container>.toolbar {
    z-index: 10;
}

body .container>.toolbar>.left>.items>.item.active {
    background-color: var(--color-primary-purple-dark) !important;
    color: var(--text-white) !important;
}

body .container>.toolbar>.right>*:not(:last-child)::after {
    content: " ";
    display: inline-flex;
    width: 1px;
    height: 20px;
    border-radius: 2px;
    background-color: var(--line-dark);
    margin: auto 12px;
}

body .container>.toolbar>.right *>.lc-dropdown * {
    white-space: nowrap;
}

body .container>.toolbar>.right>.gridfilter {
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 12px;
}

body .container>.toolbar>.right>.gridfilter>.size {
    font-weight: 700;
}

body .container>.toolbar>.right>.gridfilter:hover {
    overflow: visible;
}

body .container>.toolbar>.right>.select-period {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

body .container>.toolbar>.right>.functions {
    position: relative;
    display: flex;
    overflow: hidden;
}

body .container>.toolbar>.right>.functions:hover {
    overflow: visible;
}

body .container>.toolbar>.right>.row {
    display: flex;
    align-items: center;
    gap: 5px;
}

body .container>.toolbar>.right>.row>.lc-dropdown {
    font-size: 12px;
    font-weight: 500;
}

body .container>.toolbar>.right>.row>.lc-dropdown .ppi {
    font-weight: bolder;
}

body .container>.toolbar>.right>.row select {
    font-family: "Ubuntu", sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 5px;
    border: 0;
    cursor: pointer;
}

body .container>.toolbar>.right>.clientgroup {
    display: flex;
    align-items: center;
}

body .container>.toolbar>.right>.clientgroup span {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 10px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
}

body .container>.toolbar>.right>.last {
    display: flex;
    align-items: center;
    gap: 5px;
}

body .container>.page {
    margin: 0 auto;
    padding: 0.75rem;
}

body .container>.page>.pp-dropmenu.right {
    right: 239px;
}


.pp-dropmenu>.items>.group-solution {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .25rem;
    padding: .0rem 0;
}

.pp-dropmenu>.items .group-solution-meu-lc {
    display: flex;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: .25rem 1.5rem;
}

.pp-dropmenu>.items>.item .group-solution {
    display: flex;
    align-items: center;
    gap: .25rem;
}

.pp-dropmenu>.items>.item .label,
.pp-dropmenu .label {
    margin-bottom: 0;
}


.pp-dropmenu .color-group {
    width: .5rem;
    height: .5rem;
    border: 1px solid #fff;
    background: blue;
    border-radius: 50%;
}


.pp-dropmenu>.items>.item,
.pp-dropmenu>.items .item {
    gap: 0.3125rem;
    flex-shrink: 0;
    align-self: stretch;
}


.pp-dropmenu>.items,
.pp-dropmenu .subitems {
    width: 280px !important;
}

.pp-dropmenu .subitems>.group-solution .item {
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 0.3125rem 1.5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    align-self: stretch;
}


.pp-dropmenu .items>.group-solution .item {
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 0.3125rem 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.125rem;
}


.pp-dropmenu .subitems>.group-solution {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
}

.pp-dropmenu .subitems>.group-solution .item .label.solution-name,
.pp-dropmenu .items>.group-solution .item .label {
    color: var(--text-light);
    font-family: "Ubuntu", sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}


.pp-dropmenu .subitems>.group-solution .item .label,
.pp-dropmenu .items>.group-solution .item .label {
    margin-bottom: 0 !important;
}

.pp-dropmenu .subitems>.group-solution .item .solution {
    display: flex;
    align-items: flex-start;
    gap: .25rem;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
}


.pp-dropmenu .title-menu {
    color: var(--text-light);
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 1.4rem */
    letter-spacing: -0.035rem;
    display: flex;
    padding: .75rem 1.25rem;
    align-items: center;
    gap: 0.3125rem;
    align-self: stretch;
}

.pp-dropmenu .subitems>.group-solution .item i {
    font-size: 18px;
    font-weight: bolder;
}




.pp-dropmenu .subitems>.group-solution .item.selected,
.pp-dropmenu .items>.group-solution .item.selected {
    background-color: #eee;
}

.pp-dropmenu .subitems>.group-solution .item:hover,
.pp-dropmenu .subitems>.group-solution .item:hover .label,
.pp-dropmenu .items>.group-solution .item:hover,
.pp-dropmenu .items>.group-solution .item:hover .label {
    background-color: #f5f5f5;
    color: var(--text-default);
}

.pp-dropmenu .subitems>.group-solution .item>.label {
    display: flex;
    align-items: center;
    gap: 10px;
}


.pp-dropmenu .subitems>.group-solution .item .sufixo,
.pp-dropmenu .item .sufixo {
    font-size: 11px;
    color: var(--text-light);
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1rem */
}


.pp-dropmenu>.items hr,
.pp-dropmenu .items .group-solution hr {
    margin: 1rem auto;
    display: inline-block;
    width: calc(100% - 3rem);
    padding: 0 1.5rem;
    background-color: #d6d8e1;
    border: 0
}

.pp-dropmenu>.subitems>.group-solution .item i {
    font-size: 22px;
    font-weight: 300;
}


.pp-dropmenu .subitems .group-solution,
.pp-dropmenu .items .group-solution {
    margin: 0;
}

.pp-dropmenu .subitems .group-solution .group-title {
    font-size: 0.75rem;
    color: var(--text-light);
    padding: 5px 24px;
    display: block;
    font-weight: 500;
}


.pp-dropmenu .items .group-solution .group-title {
    font-size: 10px;
    font-family: "Ubuntu", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 1rem */
    letter-spacing: -0.025rem;
    color: var(--text-light);
    display: block;
}


/*.pp-dropmenu .group_0 .color-group {
    background-color: #5F5DAD;
}

.pp-dropmenu .group_1 .color-group {
    background-color: #0055A8;
}

.pp-dropmenu .group_2 .color-group {
    background-color: #16C2F3;
}

.pp-dropmenu .group_3 .color-group {
    background-color: #6E2D8E;
}

.pp-dropmenu .group_4 .color-group {
    background-color: #DC291E;
}

.pp-dropmenu .group_5 .color-group {
    background-color: #59BAC5;
}*/


/*.pp-dropmenu .subitems .group-solution.group_0 .group-title, .pp-dropmenu .items .group-solution.group_0 .group-title {
    color: #5F5DAD;
}

.pp-dropmenu .subitems .group-solution.group_1 .group-title, .pp-dropmenu .items .group-solution.group_1 .group-title {
    color: #0055A8;
}

.pp-dropmenu .subitems .group-solution.group_2 .group-title, .pp-dropmenu .items .group-solution.group_2 .group-title {
    color: #16C2F3;
}

.pp-dropmenu .subitems .group-solution.group_3 .group-title, .pp-dropmenu .items .group-solution.group_3 .group-title {
    color: #6E2D8E;
}

.pp-dropmenu .subitems .group-solution.group_4 .group-title, .pp-dropmenu .items .group-solution.group_4 .group-title {
    color: #DC291E;
}

.pp-dropmenu .subitems .group-solution.group_5 .group-title, .pp-dropmenu .items .group-solution.group_5 .group-title {
    color: #59BAC5;
}*/



.pp-dropmenu>.items {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
}

.pp-dropmenu>.items::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    z-index: 99;
}

.pp-dropmenu>.items::-webkit-scrollbar-thumb {
    background: #cccccf;
    border-radius: 0.25rem;
}

.pp-dropmenu>.items::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.pp-dropmenu>.items::-webkit-scrollbar-track {
    border-radius: 0.25rem;
}



/* plataforma */

.container>.topbar {
    /*background-color: #173192 !important;*/
    height: 44px !important;
}

.container>.topbar>.left>.items {
    overflow: inherit !important;
}




.pp-dropmenu {
    top: 44px !important;
}

.container>.toolbar {
    height: 36px !important;
}

.container>.toolbar>.left>.items>span.item {
    color: var(--text-light) !important;
    font-size: 12px !important;
    padding: 0 10px !important;
    height: 22px !important;
    font-weight: 400 !important;

}

.container>.toolbar>.left>.items>span.item.active:hover {
    color: #fff !important;
    background: var(--color-primary-purple-dark) !important;
}

.container>.toolbar>.left>.items {
    padding: 0 25px 0 12px;
    gap: 10px;
}

.container>.toolbar>.left>.items>span.item:hover {
    background-color: var(--background-light) !important;
    color: var(--text-default) !important;
}

.container>.toolbar>.right .element,
.container>.toolbar>.right .clientgroup {
    color: var(--text-light) !important;
}

.container>.toolbar>.right {
    height: 36px !important;
    padding: 0 12px !important;
}


.container>.toolbar>.right .input-toggle>.title {
    color: var(--text-light);
}

.toolbar>.left>.btnscroll {
    border-radius: 10px;
    border: none;
    box-shadow: 0px -4px 8px 4px #ebebeb;
    padding: 4px 10px;
}

.toolbar>.left>.btnscroll.left {
    box-shadow: 0px -4px 8px 4px #ebebeb;
    border-radius: 0px 10px 10px 0px;
    z-index: 1;
}

.pp-dropmenu>.items>.item,
.pp-dropmenu .subitems>.item {
    font-size: 13px;
    font-weight: 400 !important;
    font-family: 'Ubuntu' !important;
}

.pp-dropmenu>.items,
.pp-dropmenu .subitems {
    background-color: var(--background-white) !important;
    color: var(--text-default) !important;
}

.pp-input>.title,
.pp-input>.field>input {
    font-family: "Ubuntu" !important;
    color: var(--text-default) !important;
}


.MuiInputBase-input {
    font-family: 'Ubuntu' !important;
}

.pp-input>.field.required {
    border-left: 3px solid var(--color-secondary-lime) !important;
}


div.pp-input>.field:focus,
div.pp-textarea>.field:focus,
div.pp-dropdown>.field:focus,
div.pp-multiselect>.field:focus {
    border: 1px solid var(--color-secondary-lime) !important;
    border-left: 3px solid var(--color-secondary-lime) !important;
}

.pp-modal>.body>.header>.title {
    font-family: "Ubuntu" !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    color: var(--text-dark) !important;
}

.pp-modal>.body>.content {
    color: var(--text-default) !important;
}

spline-viewer {
    width: 100%;
    height: 100vh;
    display: block;
}


.homeflex .fix-card-content .card-frame-header-content {
    display: none !important;
}

.MuiBox-root.css-vxc1o {
    left: -30px;
}

.pp-tooltip > .body > .main > .content {
    
    background-color: var(--background-light) !important;
    box-shadow: 0px 0px 3px rgba(32, 47, 47, 0.36) !important;
    color: var(--text-default) !important;
}

.MuiGrid-root {
    font-family: 'Ubuntu' !important;
}




.lc-datatableinfinite.infinitetable-with-tooltip > .body > .row > .cells > .cell, .lc-datatableinfinite.infinitetable-with-tooltip > .body > .lc-accordion.visible > .body > .row > .cells > .cell { 
    overflow: initial !important;
}

.pp-textarea > .field > textarea, .MuiTypography-body1 {
    font-family: 'Ubuntu' !important;
    color: var(--text-default);
}

.MuiButtonBase-root {
    font-family: 'Ubuntu' !important;
    color: var(--text-default) !important;
}

.MuiSlider-valueLabel {
    color: var(--color-primary-purple-dark) !important;
}


.MuiSlider-track {
    height: 4px !important;
}
.MuiSlider-rail {
    height: 4px !important;
}

.MuiPopover-paper, .css-3bmhjh-MuiPaper-root-MuiPopover-paper, .MuiPaper-elevation8 {
    box-shadow: none !important;    
    background: transparent !important;
}