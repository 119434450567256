@font-face {
  font-family: "LiveCloudIconPack";
  src: url("fonts/LiveCloudIconPack.eot?vvxa4x");
  src: url("fonts/LiveCloudIconPack.eot?vvxa4x#iefix")
      format("embedded-opentype"),
    url("fonts/LiveCloudIconPack.ttf?vvxa4x") format("truetype"),
    url("fonts/LiveCloudIconPack.woff?vvxa4x") format("woff"),
    url("fonts/LiveCloudIconPack.svg?vvxa4x#LiveCloudIconPack") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.lci {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "LiveCloudIconPack" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lci.lci-dot-i:before {
  content: "\e905";
}
.lci.lci-dot-exclamation:before {
  content: "\e906";
}
.lci.lci-dot-check:before {
  content: "\ea04";
}
.lci.lci-dot-x:before {
  content: "\ea05";
}
.lci.lci-drag-vertical:before {
  content: "\e900";
}
.lci.lci-expand:before {
  content: "\e901";
}
.lci.lci-floppy:before {
  content: "\e902";
}
.lci.lci-flow-merge:before {
  content: "\e903";
}
.lci.lci-in-progress:before {
  content: "\e904";
}
.lci.lci-percent:before {
  content: "\e907";
}
.lci.lci-play:before {
  content: "\e908";
}
.lci.lci-restore:before {
  content: "\e909";
}
.lci.lci-Seta-direita:before {
  content: "\e90a";
}
.lci.lci-Seta-esquerda:before {
  content: "\e90b";
}
.lci.lci-star-solid:before {
  content: "\e90c";
}
.lci.lci-Union:before {
  content: "\e90d";
}
.lci.lci-upload:before {
  content: "\e90e";
}
.lci.lci-x:before {
  content: "\e90f";
}
.lci.lci-academic-cap:before {
  content: "\e910";
}
.lci.lci-adjustments:before {
  content: "\e911";
}
.lci.lci-annotation:before {
  content: "\e912";
}
.lci.lci-archive:before {
  content: "\e913";
}
.lci.lci-arrow-circle-down:before {
  content: "\e914";
}
.lci.lci-arrow-circle-left:before {
  content: "\e915";
}
.lci.lci-arrow-circle-right:before {
  content: "\e916";
}
.lci.lci-arrow-circle-up:before {
  content: "\e917";
}
.lci.lci-arrow-down:before {
  content: "\e918";
}
.lci.lci-arrow-left:before {
  content: "\e919";
}
.lci.lci-arrow-narrow-down:before {
  content: "\e91a";
}
.lci.lci-arrow-narrow-left:before {
  content: "\e91b";
}
.lci.lci-arrow-narrow-right:before {
  content: "\e91c";
}
.lci.lci-arrow-narrow-up:before {
  content: "\e91d";
}
.lci.lci-arrow-right:before {
  content: "\e91e";
}
.lci.lci-arrows-expand:before {
  content: "\e91f";
}
.lci.lci-arrow-up:before {
  content: "\e920";
}
.lci.lci-at-symbol:before {
  content: "\e921";
}
.lci.lci-backspace:before {
  content: "\e922";
}
.lci.lci-badge-check:before {
  content: "\e923";
}
.lci.lci-ban:before {
  content: "\e924";
}
.lci.lci-beaker:before {
  content: "\e925";
}
.lci.lci-bell:before {
  content: "\e926";
}
.lci.lci-bookmark:before {
  content: "\e927";
}
.lci.lci-bookmark-alt:before {
  content: "\e928";
}
.lci.lci-book-open:before {
  content: "\e929";
}
.lci.lci-briefcase:before {
  content: "\e92a";
}
.lci.lci-cake:before {
  content: "\e92b";
}
.lci.lci-calculator:before {
  content: "\e92c";
}
.lci.lci-calendar:before {
  content: "\e92d";
}
.lci.lci-camera:before {
  content: "\e92e";
}
.lci.lci-cash:before {
  content: "\e92f";
}
.lci.lci-chart-bar:before {
  content: "\e930";
}
.lci.lci-chart-pie:before {
  content: "\e931";
}
.lci.lci-chart-square-bar:before {
  content: "\e932";
}
.lci.lci-chat:before {
  content: "\e933";
}
.lci.lci-chat-alt:before {
  content: "\e934";
}
.lci.lci-chat-alt-2:before {
  content: "\e935";
}
.lci.lci-check:before {
  content: "\e936";
}
.lci.lci-checkbox:before {
  content: "\e937";
}
.lci.lci-checkbox-on:before {
  content: "\e938";
}
.lci.lci-checkbox-part:before {
  content: "\e939";
}
.lci.lci-check-circle:before {
  content: "\e93a";
}
.lci.lci-chevron-double-down:before {
  content: "\e93b";
}
.lci.lci-chevron-double-left:before {
  content: "\e93c";
}
.lci.lci-chevron-double-right:before {
  content: "\e93d";
}
.lci.lci-chevron-double-up:before {
  content: "\e93e";
}
.lci.lci-chevron-down:before {
  content: "\e93f";
}
.lci.lci-chevron-down-alt:before {
  content: "\e940";
}
.lci.lci-chevron-left:before {
  content: "\e941";
}
.lci.lci-chevron-right:before {
  content: "\e942";
}
.lci.lci-chevron-up:before {
  content: "\e943";
}
.lci.lci-chevron-up-alt:before {
  content: "\e944";
}
.lci.lci-chip:before {
  content: "\e945";
}
.lci.lci-clipboard:before {
  content: "\e946";
}
.lci.lci-clipboard-check:before {
  content: "\e947";
}
.lci.lci-clipboard-copy:before {
  content: "\e948";
}
.lci.lci-clipboard-list:before {
  content: "\e949";
}
.lci.lci-clock:before {
  content: "\e94a";
}
.lci.lci-cloud:before {
  content: "\e94b";
}
.lci.lci-cloud-download:before {
  content: "\e94c";
}
.lci.lci-cloud-upload:before {
  content: "\e94d";
}
.lci.lci-code:before {
  content: "\e94e";
}
.lci.lci-cog:before {
  content: "\e94f";
}
.lci.lci-collection:before {
  content: "\e950";
}
.lci.lci-color-swatch:before {
  content: "\e951";
}
.lci.lci-credit-card:before {
  content: "\e952";
}
.lci.lci-cube:before {
  content: "\e953";
}
.lci.lci-cube-transparent:before {
  content: "\e954";
}
.lci.lci-currency-bangladeshi:before {
  content: "\e955";
}
.lci.lci-currency-dollar:before {
  content: "\e956";
}
.lci.lci-currency-euro:before {
  content: "\e957";
}
.lci.lci-currency-pound:before {
  content: "\e958";
}
.lci.lci-currency-rupee:before {
  content: "\e959";
}
.lci.lci-currency-yen:before {
  content: "\e95a";
}
.lci.lci-cursor-click:before {
  content: "\e95b";
}
.lci.lci-database:before {
  content: "\e95c";
}
.lci.lci-desktop-computer:before {
  content: "\e95d";
}
.lci.lci-device-mobile:before {
  content: "\e95e";
}
.lci.lci-device-tablet:before {
  content: "\e95f";
}
.lci.lci-divider-horizontal:before {
  content: "\e960";
}
.lci.lci-divider-vertical:before {
  content: "\e961";
}
.lci.lci-division:before {
  content: "\e962";
}
.lci.lci-document:before {
  content: "\e963";
}
.lci.lci-document-add:before {
  content: "\e964";
}
.lci.lci-document-download:before {
  content: "\e965";
}
.lci.lci-document-duplicate:before {
  content: "\e966";
}
.lci.lci-document-remove:before {
  content: "\e967";
}
.lci.lci-document-report:before {
  content: "\e968";
}
.lci.lci-document-search:before {
  content: "\e969";
}
.lci.lci-document-text:before {
  content: "\e96a";
}
.lci.lci-dots-circle-horizontal:before {
  content: "\e96b";
}
.lci.lci-dots-horizontal:before {
  content: "\e96c";
}
.lci.lci-dots-vertical:before {
  content: "\e96d";
}
.lci.lci-download:before {
  content: "\e96e";
}
.lci.lci-duplicate:before {
  content: "\e96f";
}
.lci.lci-emoji-happy:before {
  content: "\e970";
}
.lci.lci-emoji-neutral:before {
  content: "\e971";
}
.lci.lci-emoji-sad:before {
  content: "\e972";
}
.lci.lci-exclamation:before {
  content: "\e973";
}
.lci.lci-exclamation-circle:before {
  content: "\e974";
}
.lci.lci-expand1:before {
  content: "\e975";
}
.lci.lci-external-link:before {
  content: "\e976";
}
.lci.lci-eye:before {
  content: "\e977";
}
.lci.lci-eye-off:before {
  content: "\e978";
}
.lci.lci-fast-forward:before {
  content: "\e979";
}
.lci.lci-film:before {
  content: "\e97a";
}
.lci.lci-filter:before {
  content: "\e97b";
}
.lci.lci-finger-print:before {
  content: "\e97c";
}
.lci.lci-fire:before {
  content: "\e97d";
}
.lci.lci-flag:before {
  content: "\e97e";
}
.lci.lci-folder:before {
  content: "\e97f";
}
.lci.lci-folder-add:before {
  content: "\e980";
}
.lci.lci-folder-download:before {
  content: "\e981";
}
.lci.lci-folder-open:before {
  content: "\e982";
}
.lci.lci-folder-remove:before {
  content: "\e983";
}
.lci.lci-gauge-needle:before {
  content: "\e984";
}
.lci.lci-gift:before {
  content: "\e985";
}
.lci.lci-globe:before {
  content: "\e986";
}
.lci.lci-globe-alt:before {
  content: "\e987";
}
.lci.lci-hand:before {
  content: "\e988";
}
.lci.lci-hashtag:before {
  content: "\e989";
}
.lci.lci-heart:before {
  content: "\e98a";
}
.lci.lci-home:before {
  content: "\e98b";
}
.lci.lci-identification:before {
  content: "\e98c";
}
.lci.lci-inbox:before {
  content: "\e98d";
}
.lci.lci-inbox-in:before {
  content: "\e98e";
}
.lci.lci-information-circle:before {
  content: "\e98f";
}
.lci.lci-key:before {
  content: "\e990";
}
.lci.lci-library:before {
  content: "\e991";
}
.lci.lci-light-bulb:before {
  content: "\e992";
}
.lci.lci-lightning-bolt:before {
  content: "\e993";
}
.lci.lci-link:before {
  content: "\e994";
}
.lci.lci-loading:before {
  content: "\e995";
}
.lci.lci-location-marker:before {
  content: "\e996";
}
.lci.lci-lock-closed:before {
  content: "\e997";
}
.lci.lci-lock-open1:before {
  content: "\e998";
}
.lci.lci-login:before {
  content: "\e999";
}
.lci.lci-logout:before {
  content: "\e99a";
}
.lci.lci-mail:before {
  content: "\e99b";
}
.lci.lci-mail-open:before {
  content: "\e99c";
}
.lci.lci-map:before {
  content: "\e99d";
}
.lci.lci-menu:before {
  content: "\e99e";
}
.lci.lci-menu-alt-1:before {
  content: "\e99f";
}
.lci.lci-menu-alt-2:before {
  content: "\e9a0";
}
.lci.lci-menu-alt-3:before {
  content: "\e9a1";
}
.lci.lci-menu-alt-4:before {
  content: "\e9a2";
}
.lci.lci-microphone:before {
  content: "\e9a3";
}
.lci.lci-minus:before {
  content: "\e9a4";
}
.lci.lci-minus-circle:before {
  content: "\e9a5";
}
.lci.lci-minus-sm:before {
  content: "\e9a6";
}
.lci.lci-moon:before {
  content: "\e9a7";
}
.lci.lci-music-note:before {
  content: "\e9a8";
}
.lci.lci-newspaper:before {
  content: "\e9a9";
}
.lci.lci-office-building:before {
  content: "\e9aa";
}
.lci.lci-paper-airplane:before {
  content: "\e9ab";
}
.lci.lci-paper-clip:before {
  content: "\e9ac";
}
.lci.lci-pause:before {
  content: "\e9ad";
}
.lci.lci-pencil:before {
  content: "\e9ae";
}
.lci.lci-pencil-alt:before {
  content: "\e9af";
}
.lci.lci-phone:before {
  content: "\e9b0";
}
.lci.lci-phone-incoming:before {
  content: "\e9b1";
}
.lci.lci-phone-missed-call:before {
  content: "\e9b2";
}
.lci.lci-phone-outgoing:before {
  content: "\e9b3";
}
.lci.lci-photograph:before {
  content: "\e9b4";
}
.lci.lci-pig:before {
  content: "\e9b5";
}
.lci.lci-pin:before {
  content: "\e9b6";
}
.lci.lci-play1:before {
  content: "\e9b7";
}
.lci.lci-plus:before {
  content: "\e9b8";
}
.lci.lci-plus-circle:before {
  content: "\e9b9";
}
.lci.lci-plus-sm:before {
  content: "\e9ba";
}
.lci.lci-polygons:before {
  content: "\e9bb";
}
.lci.lci-presentation-chart-bar:before {
  content: "\e9bc";
}
.lci.lci-presentation-chart-line:before {
  content: "\e9bd";
}
.lci.lci-printer:before {
  content: "\e9be";
}
.lci.lci-puzzle:before {
  content: "\e9bf";
}
.lci.lci-qrcode:before {
  content: "\e9c0";
}
.lci.lci-question-mark-circle:before {
  content: "\e9c1";
}
.lci.lci-radiobtn:before {
  content: "\e9c2";
}
.lci.lci-radiobtn-on:before {
  content: "\e9c3";
}
.lci.lci-receipt-refund:before {
  content: "\e9c4";
}
.lci.lci-receipt-tax:before {
  content: "\e9c5";
}
.lci.lci-refresh:before {
  content: "\e9c6";
}
.lci.lci-reply:before {
  content: "\e9c7";
}
.lci.lci-rewind:before {
  content: "\e9c8";
}
.lci.lci-rss:before {
  content: "\e9c9";
}
.lci.lci-save:before {
  content: "\e9ca";
}
.lci.lci-save-as:before {
  content: "\e9cb";
}
.lci.lci-scale:before {
  content: "\e9cc";
}
.lci.lci-scissors:before {
  content: "\e9cd";
}
.lci.lci-search:before {
  content: "\e9ce";
}
.lci.lci-search-circle:before {
  content: "\e9cf";
}
.lci.lci-selector:before {
  content: "\e9d0";
}
.lci.lci-server:before {
  content: "\e9d1";
}
.lci.lci-service:before {
  content: "\e9d2";
}
.lci.lci-share:before {
  content: "\e9d3";
}
.lci.lci-shield-check:before {
  content: "\e9d4";
}
.lci.lci-shield-exclamation:before {
  content: "\e9d5";
}
.lci.lci-shopping-bag:before {
  content: "\e9d6";
}
.lci.lci-shopping-cart:before {
  content: "\e9d7";
}
.lci.lci-sort-ascending:before {
  content: "\e9d8";
}
.lci.lci-sort-descending:before {
  content: "\e9d9";
}
.lci.lci-sparkles:before {
  content: "\e9da";
}
.lci.lci-speakerphone:before {
  content: "\e9db";
}
.lci.lci-star:before {
  content: "\e9dc";
}
.lci.lci-status-offline:before {
  content: "\e9dd";
}
.lci.lci-status-online:before {
  content: "\e9de";
}
.lci.lci-stop:before {
  content: "\e9df";
}
.lci.lci-sun:before {
  content: "\e9e0";
}
.lci.lci-support:before {
  content: "\e9e1";
}
.lci.lci-switch-horizontal:before {
  content: "\e9e2";
}
.lci.lci-switch-vertical:before {
  content: "\e9e3";
}
.lci.lci-table:before {
  content: "\e9e4";
}
.lci.lci-tag:before {
  content: "\e9e5";
}
.lci.lci-template:before {
  content: "\e9e6";
}
.lci.lci-terminal:before {
  content: "\e9e7";
}
.lci.lci-thumb-down:before {
  content: "\e9e8";
}
.lci.lci-thumb-up:before {
  content: "\e9e9";
}
.lci.lci-ticket:before {
  content: "\e9ea";
}
.lci.lci-translate:before {
  content: "\e9eb";
}
.lci.lci-trash:before {
  content: "\e9ec";
}
.lci.lci-trending-down:before {
  content: "\e9ed";
}
.lci.lci-trending-up:before {
  content: "\e9ee";
}
.lci.lci-truck:before {
  content: "\e9ef";
}
.lci.lci-upload1:before {
  content: "\e9f0";
}
.lci.lci-user:before {
  content: "\e9f1";
}
.lci.lci-user-add:before {
  content: "\e9f2";
}
.lci.lci-user-circle:before {
  content: "\e9f3";
}
.lci.lci-user-group:before {
  content: "\e9f4";
}
.lci.lci-user-remove:before {
  content: "\e9f5";
}
.lci.lci-users:before {
  content: "\e9f6";
}
.lci.lci-variable:before {
  content: "\e9f7";
}
.lci.lci-video-camera:before {
  content: "\e9f8";
}
.lci.lci-view-boards:before {
  content: "\e9f9";
}
.lci.lci-view-grid:before {
  content: "\e9fa";
}
.lci.lci-view-grid-add:before {
  content: "\e9fb";
}
.lci.lci-view-list:before {
  content: "\e9fc";
}
.lci.lci-volume-off:before {
  content: "\e9fd";
}
.lci.lci-volume-up:before {
  content: "\e9fe";
}
.lci.lci-wifi:before {
  content: "\e9ff";
}
.lci.lci-x1:before {
  content: "\ea00";
}
.lci.lci-x-circle:before {
  content: "\ea01";
}
.lci.lci-zoom-in:before {
  content: "\ea02";
}
.lci.lci-zoom-out:before {
  content: "\ea03";
}
