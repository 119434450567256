.iconspackage .icons {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.iconspackage .icons .icon {
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 5px;
}
.iconspackage .icons .icon .name {
  font-size: 0.8rem;
}