.lcd-alert > .all {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6000;
  background-color: rgba(255, 255, 255, 0.5);
}
.lcd-alert > .all > .body {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.25);
  padding: 15px;
  min-width: 300px;
  max-width: 500px;
}
.lcd-alert > .all > .body > .title {
  font-size: 1.1rem;
  margin-bottom: 10px;
  font-weight: 700;
}
.lcd-alert > .all > .body > .text {
  font-size: 0.9rem;
  margin-bottom: 30px;
}
.lcd-alert > .all > .body > .actions {
  display: flex;
  justify-content: end;
}