@keyframes append {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.lcd-confirmation > .all {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6000;
  background-color: rgba(0, 0, 0, 0.5);
  animation: append 0.2s linear;
}
.lcd-confirmation > .all > .body {
  /* background-color: #e2e6f3; */
  background-color: #fff;
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.25);
  padding: 15px;
  min-width: 300px;
  max-width: 500px;
  color: var(--text-default);
}
.lcd-confirmation > .all > .body > .title {
  font-size: 26px;
  margin-bottom: 30px;
  padding: 0px 15px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
}
.lcd-confirmation > .all > .body > .text {
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: 400;
  padding: 0px 15px;
  text-align: justify;
}
.lcd-confirmation > .all > .body > .actions {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
.lcd-confirmation > .all > .body > .actions > *:not(:last-child) {
  margin-right: 24px;
}