.sideModalReport {
    background-color: #fafafa;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 0.6rem;
    display: flex;
    min-height: 1.8rem;
    vertical-align: middle;
    padding-top: 5px;
}

.rowContent {
    word-break: break-all;
}

.ticketLink:link {
    text-decoration: none !important;
}

.ticketLink:hover {
    text-decoration: none !important;
}

.ticketLink:visited {
    text-decoration: none !important;
}

.ticketLink:active {
    text-decoration: none !important;
}

.lcdashboard .card-frame:has(.no-content) {
    /*background-color:rgba(255,255,255,0.6) !important;*/
    opacity: .6;
}

.lcdashboard .card-frame:has(.no-content) .block-icons {
    display: none;
}

div.comparative {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
}

div.comparative>p {
    font-size: 12px;
    color: var(--text-default);
}

.reconmentations {
    padding: 0px 8px;
    font-size: 12px;
    color: #6c63ff;
    cursor: pointer;
}

.lcdashboard .right-sidepanel {
    max-height: calc(100vh - 64px);
}

.suggestion-content {
    margin-top: 16px;
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    padding-right: 0.5rem;
}

.right-sidepanel-content .suggestion-content .suggestion-paragraph {
    margin-bottom: 1rem;
    font-size: 14px;
}

.right-sidepanel-content .sideModalReport {
    margin-top: 16px;
    max-height: calc(100vh - 240px);
    padding-right: 0.5rem;
}