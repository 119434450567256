.pp-checkbox {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}
.pp-checkbox > .ppi {
  font-size: 1.25rem;
}
.pp-checkbox > .ppi.ppi-checkbox {
  color: #cccccf;
}
.pp-checkbox > .ppi.ppi-checkbox-on {
  color: var(--color-secondary-lime) !important;
  background: var(--text-default);
  border-radius: 8px;
}
.pp-checkbox > .title {
  font-size: 0.875rem;
  color: var(--text-default);
}


.ppi.ppi-checkbox-on:before { 
  top: -1px;
  position: relative;
}
.ppi.ppi-checkbox.disabled {
  cursor: not-allowed;
  background: var(--button-disabled);
  border-radius: 8px;
}