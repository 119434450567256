.pp-expand2 {
  background-color: transparent;
  display: block;
  position: relative;
}
.pp-expand2.mb-5 {
  margin-bottom: 5px;
}
.pp-expand2.mb-10 {
  margin-bottom: 10px;
}
.pp-expand2.mb-15 {
  margin-bottom: 15px;
}
.pp-expand2 > .header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  height: 60px;
  border-radius: 5px;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: #ffffff;
  margin: 4px 0px;
}
.pp-expand2 > .header.hover:hover {
  background-color: #ececec;
}
.pp-expand2 > .header .title {
  font-size: 14px;
  /* font-weight: 600; */
  color: var(--text-default);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
}
.pp-expand2 > .header .title .ppi {
  margin-right: 23px;
  margin-left: 23px;
  font-size: 1.2rem;
  transition: all 0.5s;
}
.pp-expand2 > .header .title:not(.disabled):hover {
  color: #0580ce;
  cursor: pointer;
}
.pp-expand2 > .header > .functions {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.pp-expand2 > .header:not(.height) > .functions {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}
.pp-expand2 > .body {
  overflow: hidden;
  transition: all 0.5s ease;
  opacity: 0;
  height: 0;
}
.pp-expand2 > .body.expanded {
  overflow: visible;
  opacity: 1;
  height: auto;
}
.pp-expand2.expanded > .header > .title > .ppi {
  transform: rotate(180deg);
}