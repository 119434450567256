.severity-container {
    display: flex;
    width: 102px;
    height: 25px;
    border-radius: 4px;
    gap: 10px;
}

.severity-container span {
    text-align: center;
    margin: auto;
    padding: 4px 0;
    width: 100%;
    font-size: 12px;
}

.title-with-selector-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.suggestionAIText {
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;    
    display: -webkit-box;
    font-size: 14px;
    margin: 12px 0 12px 12px;
    color: var(--text-dark);
}