.iconvaluecardcol {
  height: 100%;
  width: auto;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: white;
  /* box-shadow: 4px 4px 18px rgba(58, 79, 135, 0.06); */
  border-radius: 1rem;
}
.iconvaluecardcol.link:hover {
  background-color: #ddd;
}
.iconvaluecardcol > .title {
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  color: #8b90a3;
}
.iconvaluecardcol > .body {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.iconvaluecardcol > .body > .icon {
  font-size: 70px;
  cursor: default;
}
.iconvaluecardcol > .body > .value {
  font-weight: 600;
  font-size: 35px;
}
.iconvaluecardcol > .body > .value > .unity {
  font-weight: 600;
  font-size: 1.5rem;
}
.iconvaluecardcol > .body > .info > .label {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  color: var(--text-default);
}
.iconvaluecardcol > .body > .info > .lc-tooltip {
  margin-left: 4px;
  color: #000;
}