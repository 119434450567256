.orgchart-container {
  height: calc(100% - 120px) !important;
}

.oc-hierarchy:hover {
  cursor: pointer;
}
.analytical > .webix_ss_body > .webix_ss_center >
.webix_ss_center_scroll >.webix_last > .webix_cell:not(.r-align) {
  text-align: right;
}