.bkpgoldresume .doughnut-chart {
  padding: 0;
}
.bkpgoldresume .doughnut-chart > .chart {
  flex-wrap: unset;
  transform: scale(0.9);
}
.bkpgoldresume .doughnut-chart > .chart > .legend {
  transform: scale(0.7);
}

.iconvaluecontent .label {
    font-size: 1.6vw !important;
    margin-left: 5px;
}