.lc-vote {
  margin-bottom: 30px;
  width: 100%;
}

.lc-vote>.question {
  font-size: 20px;
  margin: 0 0 16px 0;
  text-align: center;
  color: var(--text-default);
}

.lc-vote>.options-container {
  width: 100%;
  overflow: hidden;
  padding: 10px 0;
}

.lc-vote>.options-container>.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4px;
  padding: 0 2px;
}

.lc-vote>.options-container>.options>.option {
  flex: 1;
  aspect-ratio: 1 / 1;
  max-width: calc(2rem + 2vw);
  border-radius: 50%;
  font-size: calc(0.5rem + 0.5vw);

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
  border: 3px solid transparent;
  text-align: center;

  box-shadow: none;
  transition: all 0.3s ease, box-shadow 0.3s ease;
}

.lc-vote>.options-container>.options>.option.active {
  transform: scale(1.1);
  border-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.lc-vote>.texts {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: calc(0.6rem + 0.3vw);
  width: 100%;
}

.lc-vote>.texts>.less-text,
.lc-vote>.texts>.more-text {
  color: var(--text-default);
  max-width: 35%;
  word-wrap: break-word;
}

.lc-vote>.texts>.less-text {
  text-align: left;
}

.lc-vote>.texts>.more-text {
  text-align: right;
}