.bannersMkt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    padding-right: 0 !important; /*sobrescrever o espa�o do scrollabe-v*/
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

    .bannersMkt.visible {
        opacity: 1;
        visibility: visible;
        z-index: 1001;
    }

    .bannersMkt.exiting {
        opacity: 0;
    }

@keyframes slide-down {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

.bannersMkt .banner {
    animation: slide-up 0.3s ease-out forwards;
    width: 100%;
    height: 45vh;
    background: linear-gradient(225deg, #173192 37.50%, #2E1956 100%);
    display: flex;
    padding: 2rem;
    align-items: center;
    align-content: center;
    column-gap: 70px;
    flex-direction: column;
    flex-shrink: 0;
}

    .bannersMkt .banner .arrow-more {
        width: 64px;
        height: 64px;
        position: relative;
        top: -32px;
    }

        .bannersMkt .banner .arrow-more img {
            width: 64px;
            height: 64px;
        }

@keyframes slide-up {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}



.bannersMkt .spacer {
    height: 55vh;
    width: 100%;
}

.bannersMkt .publicidade {
    height: 45vh;
    width: 100%;
}


.bannersMkt .detalhes-solucao {
    position: relative;
    padding: 20px;
}


.bannersMkt .detalhes-solucao {
    width: 100%;
    background-color: #f1f5fb;
}

    .bannersMkt .detalhes-solucao .detalhes {
        max-width: 1920px;
        margin: 2rem auto 1rem auto;
        padding: 2rem;
        background-color: white;
        border-radius: 0.3125rem;
        box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);
        color: #2B304F;
        font-family: "Ubuntu", sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }

        .bannersMkt .detalhes-solucao .detalhes .box {
            padding: 2rem;
            margin: 2rem auto;
            border-radius: 0.3125rem;
            background: #F1F5FB;
            color: #173192;
            text-align: center;
            font-family: "Ubuntu", sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }

        .bannersMkt .detalhes-solucao .detalhes h2 {
            color: #2B304F;
            font-family: "Ubuntu", sans-serif;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            letter-spacing: -0.05rem;
            margin: 0;
        }

        .bannersMkt .detalhes-solucao .detalhes h3 {
            color: #00AEE1;
            font-family: "Ubuntu", sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            letter-spacing: -0.04rem;
            margin: 2rem 0 1rem 0;
        }

        .bannersMkt .detalhes-solucao .detalhes ul li {
            margin-bottom: 0.7rem;
        }

.bannersMkt .banner .banner-content {
    display: flex;
    max-width: 1920px;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex: 1 0;
    margin: auto;
    gap: inherit;
}

    .bannersMkt .banner .banner-content .logotipo {
        display: flex;
        width: 14%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bannersMkt .banner .banner-content .textos {
        display: flex;
        width: 45%;
        align-items: flex-start;
        align-content: space-between;
        column-gap: 48px;
        flex-wrap: wrap;
    }

        .bannersMkt .banner .banner-content .textos h1 {
            color: #fff;
            margin: 0 0 0.8rem 0;
            font-family: "Ubuntu", sans-serif;
            font-size: 1.5rem;
            line-height: 160%;
            font-weight:300;
        }

        .bannersMkt .banner .banner-content .textos p {
            color: #FFF;
            font-family: "Ubuntu", sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            line-height: 160%;
            margin: 0 0 1.5rem 0;
        }

    .bannersMkt .banner .banner-content .imagem-solucao {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

        .bannersMkt .banner .banner-content .imagem-solucao img {
            border-radius: 0.3125rem;
            width: 18.4375rem;
            height: 11.3125rem;
        }


    .bannersMkt .banner .banner-content .fechar {
        position: relative;
        top: -75px;
        color: #fff;
        font-size: 1.5rem;
        transition: opacity 0.3s ease-in-out;        
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

        .bannersMkt .banner .banner-content .fechar:hover {
            background: rgba(0,0,0,0.2);            
        }

    .bannersMkt .banner .banner-content .bg-light {
        background: #fff !important;
    }


.bannersMkt .detalhes-solucao .detalhes .pq-gtm {
    display: flex;
    align-items: center;
    align-content: flex-start;
    gap: 4rem 1rem;
}

    .bannersMkt .detalhes-solucao .detalhes .pq-gtm ul {
        flex: 1;
    }

    .bannersMkt .detalhes-solucao .detalhes .pq-gtm .tela {
        background-image: url("../../../assets/images/frameTela.png");
        width: 540px;
        height: 304px;
        text-align: center;
        padding-top: 38px;
    }

        .bannersMkt .detalhes-solucao .detalhes .pq-gtm .tela img {
            width: 412px;
            height: 232px;
            border-radius: 4px 4px 0 0;
        }

.bannersMkt .detalhes-solucao .detalhes .btns-soluction-actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: space-between;
    grid-column-gap: 48px;
    column-gap: 48px;
    flex-wrap: wrap;
    margin-top: 2rem;
}


.bannersMkt .scroll-down {
    position: fixed;
    bottom: 52px;
    right: 48px;
    font-size: 1.1em;
    cursor: pointer;
    background: #b8ccea;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    text-align: center;
    padding-top: 5px;
    color: #173192;
    border: 1px solid transparent;
}

    .bannersMkt .scroll-down:hover {
        box-shadow: rgba(58, 79, 135, 0.06) 4px 4px 18px 0px;
        opacity: 0.5;
    }
/*cores dos banners das Solucoes*/
/*infraestrutura multinuvem*/
.bannersMkt.cloudmanagedservicescorporate .banner, .bannersMkt.cloudmanagedservicesenterprise .banner, .bannersMkt.cloudmanagedservicespremier .banner, .bannersMkt.finops .banner {
    background: linear-gradient(225deg, var(--color-secondary-purple-bright) 34.40%, var(--color-primary-blue) 100%);
}

/*prote��o de dados*/
.bannersMkt.backupbasic .banner, .bannersMkt.backuppremium .banner, .bannersMkt.retencao .banner, .bannersMkt.disasterrecovery .banner {
    background: linear-gradient(225deg, var(--color-primary-magenta) 34.40%, var(--color-primary-pink) 100%);
}


/*saas*/
.bannersMkt.saasbasic .banner, .bannersMkt.saaspremium .banner, .bannersMkt.saasbackup .banner, .bannersMkt.eaeas .banner, .bannersMkt.maxsoftware .banner {
    /*background: linear-gradient(225deg, #173192 37.50%, #2E1956 100%);*/
    background: linear-gradient(225deg, #30ABE2 0%, #173192 100%);
}

/*aplica��o de neg�cios*/
.bannersMkt.diime .banner {
    background: linear-gradient(225deg, #538C0D 42.01%, #426F0B 100%);
}

.bannersMkt.doone .banner {
    background: linear-gradient(225deg, #66F 0%, #4141DC 100%);
}

.bannersMkt.heyho .banner {
    background: linear-gradient(225deg, #30ABE2 0%, #173192 100%);
}

.bannersMkt.merito .banner {
    background: linear-gradient(225deg, #DD7839 0%, #C06125 100%);
}

.bannersMkt.receeba .banner {
    background: linear-gradient(225deg, #FFC000 0%, #DD7839 100%);
}

/*seguran�a*/
.bannersMkt.edr .banner, .bannersMkt.firewall .banner, .bannersMkt.identityaccess .banner, .bannersMkt.livewatch .banner, .bannersMkt.patchmanager .banner, .bannersMkt.secureendpoint .banner {
    background: linear-gradient(225deg, var(--color-primary-purple-dark)  34.40%, var(--color-primary-blue)  100%);
}

/*gest�o e governan�a*/
.bannersMkt.maxoutsource .banner {
    background: linear-gradient(225deg, var(--color-primary-blue) 0%, var(--color-primary-pink) 100%);
}
