/*.lc-group-header-segments-button-icon {
  width: 12px;*/
/* height: 10px; */
/*left: 1px;
  top: 0px;*/
/* Textos / Claros */

/*background: #8b90a3;
  transform: matrix(0, 1, 1, 0, 0, 0);
}

.lc-group-header-segments .chevron-visible {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.lc-group-header-segments .chevron-nonvisible {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.bodyGroup.collapsed {
  max-height: 0;
  display: none;
  min-width: calc(30vw);
}

.bodyGroup {
  display: block;
  min-width: calc(30vw);
}

.lc-group-header-segments-button {*/
/* Frame 129 */

/*width: 12px;
  left: 4px;
  top: 9px;*/
/* Inside auto layout */

/*flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 6px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.lc-group-header-segments-money {*/
/* 0,00 */
/*width: 90px;
  height: 22px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;*/
/* or 22px */

/*text-align: right;
  letter-spacing: -0.05em;
  font-feature-settings: "tnum" on, "lnum" on;*/
/* Textos / Padr�o */

/*color: #525252;*/
/* Inside auto layout */

/*flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 6px;
}

.lc-group-header-segments-title {*/
/* Texto */
/*max-width: calc(100vw - 620px);
  height: 22px;*/
/* Texto/Padr�o - 14pt/Semibold */

/*font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;*/
/* or 22px */
/* Link */

/*color: var(--text-default);*/
/* Inside auto layout */

/*margin: 0px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lc-group-header-segments {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4px;
  min-width: 30vw;
  height: 30px;
  background: #fff;
  border-radius: 6px;
  margin: 4px 0px;
}

.lc-segment-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 27px;
}

.lc-title {
  display: table-cell;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #8b90a3;
}

.lc-segment-title-filter {
  display: "flex";
  justify-content: "flex-end";
}

.lc-group-body {
  min-width: 26.59vw;
  justify-content: center;
  align-content: center;
  display: grid;
  flex-direction: column;
  align-items: center;*/
/* padding: 0px; */

/*top: 75px;
}


.lc-group-row {*/
/* Item de piv� */
/*padding-left: 32px;
  border-bottom: 1px solid #ebeff8;
  width: 100%;
  height: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
  max-width: inherit;
  display: block;
}

.lc-group-row-inner-text {
  display: inline-block;
  max-width: calc(100vw - 600px);
  height: 22px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: var(--text-default);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lc-group-row-inner-text-sidemodal {
  max-width: calc(100vw - 600px);
  height: 22px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: var(--text-default);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lc-group-row-inner-money {
  width: 90px;
  height: 22px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: right;
  letter-spacing: -0.05em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: var(--text-default);
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 6px;
}

.lc-group-row-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: inherit;
  height: 22px;
  justify-content: space-between;
}

.lc-group-row-inner>.row>.lc-iconlink.small {
  visibility: hidden;
}

.lc-group-row-inner:hover>.row>.lc-iconlink.small {
  visibility: visible;
}

.lc-group-header-segments>.row>.lc-iconlink.small {
  visibility: hidden;
}

.lc-group-header-segments:hover>.row>.lc-iconlink.small {
  visibility: visible;
}

.lc-selectLabel {
  position: static;
  height: 18px;
  left: 0px;
  top: 0px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  line-height: 150%;
  font-size: 12px;
  line-height: 150%;
  margin: 3px 0px;
}

.lc-group-row>.lc-group-row-inner>.pp-tooltip {
  width: 80%;
}*/


.cards-ea {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Isso cria duas colunas de tamanho igual */
    gap: 0.75rem; /* Espaço entre os itens */
}

    .cards-ea .card {
        background-color: #fff;
        border-radius: 0.25rem;
        padding: 0.75rem;
        box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.75rem;
    }

        .cards-ea .card > div {
            max-height: 400px;
        }

        .cards-ea .card:hover {
            box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.2);
        }

    .cards-ea .cab-ea {        
        background: #eaeef6;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        color: #2E1956;
        font-family: "Ubuntu", sans-serif;
        font-size: .75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
    }

    .cards-ea .title-country {
        color: #2E1956;
        font-family: "Ubuntu", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        letter-spacing: -.04rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

        .cards-ea .title-country img {
            margin-right: .5rem;
            opacity: .3;
            filter: grayscale(1);
            transition: opacity 0.3s ease-in;
        }


    .cards-ea .card:hover .title-country img {
        opacity: 1;
        filter: grayscale(0);
    }

    .cards-ea .subtitle-cc {
        font-family: "Ubuntu", sans-serif;
        font-size: .75rem;
        letter-spacing: -.04em;
        padding: 0;
        color: #273b85;
        margin: 1.5rem 0 1rem 0;
    }



.row-ea-home {
    display: grid;
    grid-template-columns: 55% 20% 25%; /* Define as proporções das colunas */
    gap: 0rem; /* Espaçamento entre as colunas */
}


.row-ea {
    display: grid;
    grid-template-columns: 40% 15% 20% 25%; /* Define as proporções das colunas */
    gap: 0rem; /* Espaçamento entre as colunas */    
}

    .row-ea:hover, .row-ea-home:hover {
        background-color: #F1F5FB;
    }    

.row-total {    
    margin-top: 0.5rem;
    border-top: 1px solid #ebebeb;
    font-weight: bold;
}

.cell-total-users {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}


.pp-expand2 > .header:not(.height) > .functions {
    width: auto;
}

.status-ea {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 10px;
}

.hover-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 20px;
    padding: 5px 10px; /* Espaçamento interno (opcional) */
    color: #444;
}

    .hover-info > div {
        display: flex;
        align-items: center;
        gap: 5px;
    }




.hoverable .hover-info {
    display: none; /* Esconde por padrão */
}

.hoverable:hover .hover-info {
    display: flex; /* Mostra no hover */
}

.fixed-hover .hover-info {
    display: flex; /* Sempre mostra */
}
