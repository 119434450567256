.lc-accordion {
  overflow: visible;
}
.lc-accordion.accordionBackground {
  background-color: #fff;
  margin-top: 5px;
}
/* .lc-accordion > .header > .chevron {
  transform: rotate(-90deg);
}
.lc-accordion.visible > .header > .chevron {
  transform: rotate(0deg);
} */
.lc-accordion > .header {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-default);
  cursor: pointer;
}
.lc-accordion > .header.fontSize {
  padding: 5px 0px 5px 0px;
  font-size: 1.3rem;
}
.lc-accordion > .header > .chevron {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  overflow: visible;
}
.lc-accordion > .header > .chevron.rotate {
  transform: rotate(-90deg);
}
.lc-accordion.visible > .header > .chevron.rotate {
  transform: rotate(0deg);
}
.lc-accordion > .header > .chevron.rotate {
  transition: transform 0.25s ease-in-out;

}
/* .lc-accordion > .header > .chevron:hover {
  font-size: 1.5rem;
} */
.lc-accordion > .header > .title {
  display: flex;
  align-items: inherit;
  padding-left: 0.8rem;
  width: 100%;
}
.lc-accordion > .body {
  margin-left: 20px;
  height: auto;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}
.lc-accordion > .body.collapsed {
  max-height: 0;
}