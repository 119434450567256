.titulos {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: var(--text-default);
  flex: none;
  order: 0;
  flex-grow: 0;
  height: 26px;
  position: static;
  left: 0px;
  top: 0px;
  margin: 10px 0px;
}

.ruleName {
  width: 95%;
}

.ruleName > .MuiInputBase-root.MuiOutlinedInput-root, 
.condValue > .MuiInputBase-root.MuiOutlinedInput-root
 {
  height: 28px !important;
  margin-top: 0;
}
.percentField > .MuiInputBase-root.MuiOutlinedInput-root {
  height: 28px !important;
  margin-top: 5px;
}