.input-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}
.input-checkbox > .lci {
  font-size: 1.25rem;
}
.input-checkbox > .lci.lci-checkbox {
  color: #cccccf;
}
.input-checkbox > .lci.lci-checkbox-on {
  color: var(--color-secondary-lime) !important;
}
.input-checkbox > .title {
  font-size: 0.875rem;
  color: var(--text-default);
}