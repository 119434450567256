.file-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0;
    margin: 0;
    list-style: none;
}

.file-list li {
    display: flex;
    align-items: center;
    margin-right: 10px;
    min-width: 50px;
    /* Set minimum width */
    max-width: 200px;
    /* Adjust this value as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-item {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    /* Prevent shrinking below min-width */
}

.file-icon {
    margin-right: 10px;
    flex-shrink: 0;
}

.file-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.file-details p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.delete-button {
    background: none;
    border: none;
    color: #d9534f;
    cursor: pointer;
    margin-left: auto;
}

.upload-progress-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.upload-progress {
    width: 100%;
    margin-bottom: 5px;
}

.hide {
    display: none;
}

.scrollable-containerA {
    overflow-x: auto;
    /* Enable horizontal scrolling */
    padding-bottom: 5px;
    /* Space to avoid scrollbar overlap */
}

.delete-button {
    background: none;
    border: none;
    color: #d9534f;
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}