.ellipsis-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-default);
}
.patchManagerHome {
    min-height: 45% !important;
    width: 100%
}

.iconvaluecontent.systemsPatchManager > .truncate-container > .truncate-text.description,
.iconvaluecontent.systemsPatchManager > .truncate-container > .truncate-text.data > div{
    color: #fff !important;
}
.automation-icons { 
    min-width: 15vw;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: var(--background-lightest);
    border-radius: 5px;
}

.implantation-focused {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 10px;
    padding: 10px
}