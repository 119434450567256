.cardTitle {
	margin: auto;
	font-size: 16px;
	font-weight: 600;
	font-family: "Ubuntu", sans-serif;
	color: #0068b2;
}

.cardSubTitle {
	margin: auto;
	font-size: 16px;
	font-weight: 600;
	font-family: "Ubuntu", sans-serif;
	color: #0068b2;
}

.summaryContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 5px 10px 5px 10px;
}

.licenseSummaryLine {
	font-size: 12px;
	margin-top: 6px;
}

.licenseCount {
	font-size: 16px;
	font-weight: 600;
}

.globalSummaryLine {
	font-size: 18px;
	font-weight: 600;
	margin-top: 6px;
}

.followUpFlagsContainer {
	display: flex;
	justify-content: space-between;
	padding: 20px 20px;
	font-family: "Ubuntu", sans-serif;
}

.flagItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-family: "Ubuntu", sans-serif;
}

.flagItem img {
	width: 64px;
	height: 48px;
	margin-bottom: 10px;
}

.summaryLineByCountry {
	font-size: 12px;
	margin-top: 6px;
}

.GAP {
	color: #428042;
	width: 50%;
}