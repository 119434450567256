.formModalDescription {
    font-family: 'Ubuntu';
    font-size: 14px;
}

.formModalFieldset {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid lightgray;
    padding: 10px 10px 5px 10px;
    margin: 10px 10px 0 0; */


    
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding:12px;    
    border: 1px solid #ddd;

}



.formModalFieldsetTitle {
    padding: 0 10px 0 10px;
    font-size: 14px;
    font-family: 'Ubuntu';
}

.contentInformation {
    font-family: "Ubuntu", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.DashboardTitulo {
    font-family: "Ubuntu", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #8B90A3;
}

.MuiStep-horizontal {
    padding: 1px 1px 1px 1px !important;
}

.MuiStepConnector-alternativeLabel {
    top: 12px;
    left: calc(-50% + 24px) !important;
    right: calc(50% + 24px) !important;
    position: absolute;
}

/* .MuiPaper-root {
    max-width: 70% !important;
} */

.formModalWizardFormArea {    
    min-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff; 
}

.infoWizardFieldGroup {
    min-width: calc(100% - 100px);
    min-width: calc(100%);
    display: grid;
    /* scroll-behavior: auto; */
    overflow-y: auto;
}

.formModalWizardFieldGroup {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;    
    gap: 12px;
    min-height: 23vh;
    margin-bottom: 10px;
    align-items: stretch;
    align-content: stretch
}

.pp-modal .formModalWizardFieldGroup {    
    height: 36vh;
}

.wizard-info-container {
    background-color: #F1F5FB;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    align-items: start;
}

.wizard-info-icon {
    font-size: 22px; /* Size of the info icon */
    margin-right: 8px;
    min-width: 24px;
    min-height: 24px;
}

.wizard-info-text {
    color: #2B304F; /* Color for the text */
}

.formModalWizardButtons {
    width: 100%;
    display: block;
    margin-top: 25px;
    /* width: 800px; */
}

.formModalWizardReviewArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-size: 14px;
    height: 33vh;
    margin-bottom: 20px;
}


.formModalWizardReviewArea ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.formModalWizardReviewDesc {
    /* min-width: calc(100vw - 100px); */
    margin-bottom: 24px;
    font-weight: 600;
}

.formModalWizardReviewFields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
    border: 2px dotted lightgray;
    padding: 10px;
}

.formModalWizardReviewButtons {
    display: flex;
    width: 100%;
    justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active {
    color: black !important;
}

.MuiStepConnector-horizontal {
    margin: 10px !important;
}


.pp-modal .MuiStepper-root {    
    padding: 0 0 24px 0;
}