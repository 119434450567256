.pp-expand {
  background-color: transparent;
  display: block;
  position: relative;
}
.pp-expand.mb-5 {
  margin-bottom: 5px;
}
.pp-expand.mb-10 {
  margin-bottom: 10px;
}
.pp-expand.mb-15 {
  margin-bottom: 15px;
}
.pp-expand > .header {
  display: flex;
  justify-content: space-between;
}
.pp-expand > .header.hover:hover {
  background-color: #ececec;
}
.pp-expand > .header .title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-default);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
}
.pp-expand > .header .title .ppi {
  margin-right: 10px;
  font-size: 1.2rem;
  transition: all 0.5s;
}
.pp-expand > .header .title:not(.disabled):hover {
  color: var(--color-primary-blue);
  cursor: pointer;
}
.pp-expand > .header:not(.disabled):hover {
  cursor: pointer;
}
.pp-expand > .header > .functions {
  display: flex;
  align-items: center;
}
.pp-expand > .body {
  overflow: hidden;
  transition: all 0.5s ease;
  opacity: 0;
  height: 0;
}
.pp-expand > .body.expanded {
  overflow: visible;
  opacity: 1;
  height: auto;
}
.pp-expand.expanded > .header > .title > .ppi {
  transform: rotate(180deg);
}