.container-login {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-self: center;
    max-width: 1300px;
}

.container-login .logolc {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 52px);
}

.container-login .logolc img {
    width: 300px;
}


.container-login .content-login {
    margin-left: 5%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
}

.container-login .content-login .title {
    display: flex;
    align-items: flex-start;
}

.container-login .content-login .title h2 {
    width: 30.375rem;
    color: #8C40E3;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px !important;
}

.container-login .content-login .buttons {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap:2rem;
}

.container-login .content-login .buttons button {
    width: 14rem;
    height: 2.5rem;
    border: 2px solid #471FCC;
    background-color: #fff;
    border-radius: 20px;
    outline: none;
    color: #471FCC;
    font-family: 'Ubuntu';
    font-size: 14px;
    font-weight: 600;
}

.container-login .content-login .buttons button:not(:last-child) {
    margin-bottom: 14px;
}

.container-login .content-login .buttons button:hover {    
    box-shadow: 0 0 4px 6px rgba(71, 31, 204, 0.06);
    border: 2px solid #8C40E3;
    color:#8C40E3;
    cursor: pointer;
}

.container-login .content-login .buttons .emailLogin {
    height: 0px;
    overflow: hidden;
    transition: height 0.5s ease;
}

.container-login .content-login .buttons .emailLogin.visible {
    height: 200px;
    padding-top: 10px;
}

.container-login .content-login .authorize {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    display: inline;
    color: #471FCC;
    cursor: pointer;
}

.container-login .content-login span:hover {
    color: #8C40E3;
}

.container-login .spline {
    width: 100%;
    max-width: 500px;
}

.container-login .authorize-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.container-login .logo-container {
    display: flex;
    flex-direction: column;
    gap:2rem;
}

.container-login button.pp-button { 
    background-color: #E2E6F3;
    color: #2E1956 !important;
}

button.pp-button.bg-info {
    background-color: #9FA3FF !important;
    color: #2E1956 !important;
}

button.pp-button.bg-info:hover {
    background-color: #C3C6FD !important;
}

.text-danger {
    color:#DC291E !important;
}