.lc-selectLabel {
  position: static;
  width: 55px;
  height: 18px;
  left: 0px;
  top: 0px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  line-height: 150%;
  font-size: 12px;
  line-height: 150%;
  margin: 3px 0px;
}

.lc-fixedFilter {
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;
  width: 100%;
  top: 110px;
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  display: inline-block;
  position: fixed;
  z-index: 99;
}

.echarts-for-react text[fill="#2B304F"] {
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: -0.05em;
}

.lc-title {
/*  display: table-cell;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #8b90a3;
  width: 300px;*/
}

.lc-segment-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 27px;
}

.tooltip-custom {
  max-width: 300px;
  word-break: break-word;
}