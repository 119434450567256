.headerLog {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
    border-bottom: 2px solid #e9ecf6;
    padding: 0px 5px;
    font-size: 0.75rem;
    color: #2E1956;
}

    .headerLog div {
        font-weight: bold;
        margin-bottom: .4rem;
    }

.bodyLog {
    margin-bottom: 12px;
}

    .bodyLog > .row {
        display: flex;
        font-size: 0.75rem;
        font-weight: 400;
        justify-content: space-between;
        padding: 0px 5px;
        border-radius: 4px;
        color: #2E1956;
    }

.headerLog > .resources {
    max-width: 50%;
    min-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerLog > .resources_focus {
    max-width: 25%;
    min-width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerLog > .eventTime_focus {
    max-width: 12.5%;
    min-width: 12.5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bodyLog > .row:hover {
    background-color: #e9ecf6;
    border-radius: 4px;
}

.bodyLog > .row > .resources {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 50%;
}

.bodyLog > .row > .resources_focus {
    max-width: 25%;
    min-width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bodyLog > .row > .eventTime_focus {
    max-width: 12.5%;
    min-width: 12.5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
