@import "/src/assets/lib/lcfi/file_icons.css";
:root {
    --color-primary-blue: #471FCC;
    --color-primary-purple-dark: #2E1956;
    --color-primary-purple: #8C40E3;
    --color-primary-magenta: #AB19E7;
    --color-primary-purple-light: #966CF3;
    --color-primary-pink: #DA43F2;
    --color-secondary-purple-bright: #9FA3FF;
    --color-secondary-cyan: #00DBFF;
    --color-secondary-lime: #C9FF46;
    --color-secondary-black: #000000;
    --color-secondary-mint: #A6FFE8;
    --color-secondary-green-dark: #255A4B;
    --color-secondary-green-light: #34C38F;
    --background-lightest: #FAFBFF;
    --background-light: #E2E6F3;
    --background-neutral: #EBEBEB;
    --background-white: #FFFFFF;
    --background-purple-dark: #2E1956;
    --text-default: #2E1956;
    --text-dark: #6B6F7D;
    --text-light: #8B90A3;
    --text-link: #00DBFF;
    --text-link-hover: #471FCC;
    --text-white: #FFFFFF;
    --icon-default: #2E1956;
    --icon-blue: #471FCC;
    --icon-not-selected: #898DA6;
    --icon-disabled: #EBEBEB;
    --icon-white: #FFFFFF;
    --status-green: #3EBB3F;
    --status-yellow: #F6DE00;
    --status-red: #DC291E;
    --line-default: #E2E6F3;
    --line-dark: #9196A7;
    --line-light: #EBEBEB;
    --line-outline: #CCCCCF;
    --menu-item-active: #2E1956;
    --menu-item-hover: #E2E6F3;
    --button-primary-default: #9FA3FF;
    --button-primary-hover: #9FA3FF;
    --button-secondary-default: #E2E6F3;
    --button-secondary-hover: #E2E6F3;
    --button-disabled: #8B90A3;
}

.pp-dropdown > .field {
    height: 28px !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

    *:focus {
        outline: none;
    }

body .container > .page {
    margin: 0 auto;
}
.MuiButtonBase-root {
    font-family: 'Ubuntu' !important;
    padding: 0px !important;
    font-size: 12px !important;
}

.ai-gradient-text {
    font-weight: 400 !important;
    background: linear-gradient(90deg, #00FF00 0%, #00FFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: 'Ubuntu'
}
 
.ai-gradient-icon {

    background: linear-gradient(90deg, #00FF00 0%, #00FFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container > .page {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.echarts-for-react g text {
    width: 150px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

    .echarts-for-react g text :hover {
        overflow: visible !important;
    }

div.pp-input > .field, div.pp-dropdown > .field, div.pp-multiselect > .field {
    max-height: 28px !important;
}

.pp-input > .suggestions, .pp-input > .loading {
    top: auto !important;
}

div.pp-textarea > .field {
    max-height: 70px;
    align-items: baseline;
}

.pp-inputfile > .body {
    height: auto !important;
}

h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1.25rem;
}

h3 {
    font-size: 1.1rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 0.9rem;
    font-weight: 400;
}

h6 {
    font-size: 0.8rem;
    font-weight: 400;
}

hr {
    height: 1px;
    background-color: #cccccf;
    border: 0;
    margin: 0.5rem 0;
}

ul,
li {
    padding: revert;
}

.hide {
    display: none !important;
}

.shrunked {
    width: 0 !important;
    overflow: hidden !important;
}

.fullheigth {
    height: 100%;
}

.link {
    cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--primary);
}

.link-text {
    cursor: pointer;
    color: var(--text-link);
    font-weight: bold;
    text-decoration: underline;
}

.hover:hover {
    background-color: #eee;
}

.bold {
    font-weight: bold;
}

.bolder {
    font-weight: bolder;
}

.lclink {
    width: 38px;
    height: 38px;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

    .lclink:hover {
        background-color: #cccccf;
    }

.lctaglink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1.2rem;
    border: 0;
    /* box-shadow: 4px 4px 18px rgba(58, 79, 135, 0.06); */
    border-radius: 8px;
    height: 2.5rem;
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 0.2rem;
}

    .lctaglink > div {
        display: flex;
        align-items: center;
    }

    .lctaglink i {
        font-size: 1.5rem;
    }

    .lctaglink:hover {
        outline: 3px auto #666;
    }

button.lc-button,
button.pp-button, #react-joyride-portal button[aria-label='Pular'],#react-joyride-portal button[aria-label='Voltar'] {
    padding: 0.3rem 1.2rem;
    border: 0;
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    min-width: 135px;
    background-color: var(--background-light) !important;
    color:var(--text-default) !important;
    transition: background-color 0.3s ease, color 0.3s ease; 
}


button.lc-button:hover,
button.pp-button:hover, .react-joyride__tooltip button[aria-label='Pular']:hover, .react-joyride__tooltip button[aria-label='Voltar']:hover {
    background-color: #ECEEF6 !important;
    transition: background-color 0.3s ease, color 0.3s ease;
}



    button.lc-button > .small,
    button.pp-button > .small {
        height: 30px;
        min-width: 50px;
    }

   

    button.lc-button.small,
    button.pp-button.small {
        padding: 0.2rem 0.5rem;
        border-radius: 0.25rem;
        height: 24px;
        font-size: 0.6rem;
    }

    button.lc-button.disabled,
    button.pp-button.disabled {
        background-color: #ebebeb !important;
        color: var(--text-light) !important;
        cursor: not-allowed;
    }

    button.lc-button.grey,
    button.pp-button.grey {
        background-color: #cccccf !important;
        color: #000 !important;
    }

.lc-buttons {
    display: flex;
    align-items: center;
    gap: 0;
}

    .lc-buttons > .active {
        background-color:var(--color-primary-blue);
        color: #fff;
    }

    .lc-buttons > :not(.active) {
        background-color: #EAEEF6;
    }

    .lc-buttons > * {
        border-radius: 0 !important;
    }

        .lc-buttons > *:first-child {
            border-radius: 4px 0 0 4px !important;
        }

        .lc-buttons > *:last-child {
            border-radius: 0 4px 4px 0 !important;
        }

.lc-pivot-segments {
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-flow: row wrap;
}

.bg-primary, .bg-info {
    background-color: var(--color-primary-purple-dark) !important;
    color: #fff !important;
}


.lc-button.bg-primary, .lc-button.bg-info {
    background-color: var(--button-primary-default) !important;
    color: var(--text-default) !important;    
    font-family: 'Ubuntu' !important;
}

.react-joyride__tooltip button[aria-label='Próximo'], .react-joyride__tooltip button[aria-label='Fim'] {
    background-color: var(--button-primary-default) !important;
    color: var(--text-default) !important;    
    font-family: 'Ubuntu' !important;
}

.lc-button.bg-primary:hover, .lc-button.bg-info:hover, .react-joyride__tooltip button:hover {
    background-color: #C3C6FD !important; /* Ajuste a cor para uma versão mais clara ou escura */
}

.bg-secondary {
    background-color: #1b3a7a !important;
    color: #fff !important;
}

.bg-danger {
    background-color: #e64646 !important;
    color: #fff !important;
}

.bg-success {
    background-color: #a5bf3e !important;
    color: #000 !important;
}

.bg-warning {
    background-color: #ffb800 !important;
    color: #000 !important;
}


.bg-link {
    background-color: #0580ce !important;
    color: #fff !important;
}

.bg-grey {
    background-color: #cccccf !important;
    color: #000 !important;
}

.bg-purple {
    background-color: #5969cd !important;
    color: #fff !important;
}

.bg-pink {
    background-color: #e273c5 !important;
    color: #fff !important;
}

.bg-green {
    background-color: #a5bf3e !important;
    color: #fff !important;
}

.bg-brown {
    background-color: #b48c48 !important;
    color: #fff !important;
}

.text-primary {
    color: var(--text-default) !important;
}

.text-secondary {
    color: var(--text-blue) !important;
}

.text-danger {
    color: var(--status-red) !important;
}

.text-success {
    color: var(--status-green) !important;
}

.text-warning {
    color: var(--status-yellow) !important;
}

.text-lime {
    color: var(--color-secondary-lime) !important;
}

.text-info {
    color: #b8ccea !important;
}

.text-link {
    color: var(--text-link) !important;
}

.text-grey {
    color: #cccccf !important;
}

.text-purple {
    color: #5969cd !important;
}

.text-pink {
    color: #e273c5 !important;
}

.text-green {
    color: #a5bf3e !important;
}

.text-brown {
    color: #b48c48 !important;
}

.scrollable-v {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.8rem;
}

    .scrollable-v::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;
        background-color: #eee;
        z-index: 99;
    }

    .scrollable-v::-webkit-scrollbar-thumb {
        background: #cccccf;
        border-radius: 0.25rem;
    }

        .scrollable-v::-webkit-scrollbar-thumb:hover {
            background: #ddd;
        }

    .scrollable-v::-webkit-scrollbar-track {
        border-radius: 0.25rem;
    }

.scrollable-h {
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 0.5rem;
    /* z-index: 99; */
}

    .scrollable-h::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }

    .scrollable-h::-webkit-scrollbar-thumb {
        background: #cccccf;
        border-radius: 0.25rem;
    }

        .scrollable-h::-webkit-scrollbar-thumb:hover {
            background: #ddd;
        }

    .scrollable-h::-webkit-scrollbar-track {
        border-radius: 0.25rem;
    }

.lci-1x {
    font-size: 1rem !important;
}

.lci-2x {
    font-size: 1.2rem !important;
}

.lci-3x {
    font-size: 1.4rem !important;
}

.lci-4x {
    font-size: 1.6rem !important;
}

.lci-5x {
    font-size: 1.8rem !important;
}

.lci-6x {
    font-size: 2rem !important;
}

.lci-7x {
    font-size: 2.5rem !important;
}

.lci-rotate-180 {
    transform: rotate(180deg) !important;
}

.lci-rotate-90 {
    transform: rotate(90deg) !important;
}

.lci-rotate-270 {
    transform: rotate(270deg) !important;
}

.font-xs {
    font-size: 0.7rem !important;
}

.font-sm {
    font-size: 0.8rem !important;
}

.font-md {
    font-size: 1rem !important;
}

.font-lg {
    font-size: 1.25rem !important;
}

.font-xl {
    font-size: 1.5rem !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-dark {
    font-weight: 500;
}

.text-darker {
    font-weight: 700;
}

.font-1x {
    font-size: 6px;
}

.font-2x {
    font-size: 8px;
}

.font-3x {
    font-size: 10px;
}

.font-4x {
    font-size: 12px;
}

.font-5x {
    font-size: 14px;
}

.font-6x {
    font-size: 16px;
}

.font-7x {
    font-size: 20px;
}

.font-8x {
    font-size: 24px;
}

.font-9x {
    font-size: 26px;
}

@media only screen and (max-width: 600px) {
    .pc-only {
        display: none !important;
    }
}

@media only screen and (min-width: 600px) {
    .mobile-only {
        display: none !important;
    }
}

.m-1 {
    margin: 1px;
}

.m-2 {
    margin: 2px;
}

.m-3 {
    margin: 5px;
}

.m-4 {
    margin: 8px;
}

.m-5 {
    margin: 10px;
}

.m-6 {
    margin: 15px;
}

.m-7 {
    margin: 20px;
}

.mt-1 {
    margin-top: 1px;
}

.mt-2 {
    margin-top: 2px;
}

.mt-3 {
    margin-top: 5px;
}

.mt-4 {
    margin-top: 8px;
}

.mt-5 {
    margin-top: 10px;
}

.mt-6 {
    margin-top: 15px;
}

.mt-7 {
    margin-top: 20px;
}

.mb-1 {
    margin-bottom: 1px;
}

.mb-2 {
    margin-bottom: 2px;
}

.mb-3 {
    margin-bottom: 5px;
}

.mb-4 {
    margin-bottom: 8px;
}

.mb-5 {
    margin-bottom: 10px;
}

.mb-6 {
    margin-bottom: 15px;
}

.mb-7 {
    margin-bottom: 20px;
}

.ml-1 {
    margin-left: 1px;
}

.ml-2 {
    margin-left: 2px;
}

.ml-3 {
    margin-left: 5px;
}

.ml-4 {
    margin-left: 8px;
}

.ml-5 {
    margin-left: 10px;
}

.ml-6 {
    margin-left: 15px;
}

.ml-7 {
    margin-left: 20px;
}

.mr-1 {
    margin-right: 1px;
}

.mr-2 {
    margin-right: 2px;
}

.mr-3 {
    margin-right: 5px;
}

.mr-4 {
    margin-right: 8px;
}

.mr-5 {
    margin-right: 10px;
}

.mr-6 {
    margin-right: 15px;
}

.mr-7 {
    margin-right: 20px;
}

.mv-1 {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mv-2 {
    margin-top: 2px;
    margin-bottom: 2px;
}

.mv-3 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mv-4 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mv-5 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mv-6 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mv-7 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mh-1 {
    margin-left: 1px;
    margin-right: 1px;
}

.mh-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.mh-3 {
    margin-left: 5px;
    margin-right: 5px;
}

.mh-4 {
    margin-left: 8px;
    margin-right: 8px;
}

.mh-5 {
    margin-left: 10px;
    margin-right: 10px;
}

.mh-6 {
    margin-left: 15px;
    margin-right: 15px;
}

.mh-7 {
    margin-left: 20px;
    margin-right: 20px;
}

.p-1 {
    padding: 1px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-3 {
    padding: 5px !important;
}

.p-4 {
    padding: 8px !important;
}

.p-5 {
    padding: 10px !important;
}

.p-6 {
    padding: 15px !important;
}

.p-7 {
    padding: 20px !important;
}

.pt-1 {
    padding-top: 1px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-3 {
    padding-top: 5px !important;
}

.pt-4 {
    padding-top: 8px !important;
}

.pt-5 {
    padding-top: 10px !important;
}

.pt-6 {
    padding-top: 15px !important;
}

.pt-7 {
    padding-top: 20px !important;
}

.pb-1 {
    padding-bottom: 1px !important;
}

.pb-2 {
    padding-bottom: 2px !important;
}

.pb-3 {
    padding-bottom: 5px !important;
}

.pb-4 {
    padding-bottom: 8px !important;
}

.pb-5 {
    padding-bottom: 10px !important;
}

.pb-6 {
    padding-bottom: 15px !important;
}

.pb-7 {
    padding-bottom: 20px !important;
}

.pl-1 {
    padding-left: 1px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.pl-3 {
    padding-left: 5px !important;
}

.pl-4 {
    padding-left: 8px !important;
}

.pl-5 {
    padding-left: 10px !important;
}

.pl-6 {
    padding-left: 15px !important;
}

.pl-7 {
    padding-left: 20px !important;
}

.pr-1 {
    padding-right: 1px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-3 {
    padding-right: 5px !important;
}

.pr-4 {
    padding-right: 8px !important;
}

.pr-5 {
    padding-right: 10px !important;
}

.pr-6 {
    padding-right: 15px !important;
}

.pr-7 {
    padding-right: 20px !important;
}

.pv-1 {
    padding: 1px 0 !important;
}

.pv-2 {
    padding: 2px 0 !important;
}

.pv-3 {
    padding: 5px 0 !important;
}

.pv-4 {
    padding: 8px 0 !important;
}

.pv-5 {
    padding: 10px 0 !important;
}

.pv-6 {
    padding: 15px 0 !important;
}

.pv-7 {
    padding: 20px 0 !important;
}

.ph-1 {
    padding: 0 1px !important;
}

.ph-2 {
    padding: 0 2px !important;
}

.ph-3 {
    padding: 0 5px !important;
}

.ph-4 {
    padding: 0 8px !important;
}

.ph-5 {
    padding: 0 10px !important;
}

.ph-6 {
    padding: 0 15px !important;
}

.ph-7 {
    padding: 0 20px !important;
}

.w-100 {
    width: 100px !important;
}

.w-200 {
    width: 200px !important;
}

.w-300 {
    width: 300px !important;
}

.w-400 {
    width: 400px !important;
}

.w-500 {
    width: 500px !important;
}

.w-600 {
    width: 600px !important;
}

.w-700 {
    width: 700px !important;
}

.w-800 {
    width: 800px !important;
}

.w-900 {
    width: 900px !important;
}

.w-1000 {
    width: 1000px !important;
}

.h-100 {
    height: 100px !important;
}

.h-200 {
    height: 200px !important;
}

.h-300 {
    height: 300px !important;
}

.h-400 {
    height: 400px !important;
}

.h-500 {
    height: 500px !important;
}

.h-600 {
    height: 600px !important;
}

.h-700 {
    height: 700px !important;
}

.h-800 {
    height: 800px !important;
}

.h-900 {
    height: 900px !important;
}

.h-1000 {
    height: 1000px !important;
}

.w-10p {
    width: 10% !important;
}

.w-15p {
    width: 15% !important;
}

.w-20p {
    width: 20% !important;
}

.w-25p {
    width: 25% !important;
}

.w-30p {
    width: 30% !important;
}

.w-40p {
    width: 40% !important;
}

.w-45p {
    width: 45% !important;
}

.w-50p {
    width: 50% !important;
}

.w-55p {
    width: 55% !important;
}

.w-60p {
    width: 60% !important;
}

.w-70p {
    width: 70% !important;
}

.w-75p {
    width: 75% !important;
}

.w-80p {
    width: 80% !important;
}

.w-90p {
    width: 90% !important;
}

.w-100p {
    width: 100% !important;
}

.h-10p {
    height: 10% !important;
}

.h-15p {
    height: 15% !important;
}

.h-20p {
    height: 20% !important;
}

.h-25p {
    height: 25% !important;
}

.h-30p {
    height: 30% !important;
}

.h-40p {
    height: 40% !important;
}

.h-45p {
    height: 45% !important;
}

.h-50p {
    height: 50% !important;
}

.h-55p {
    height: 55% !important;
}

.h-60p {
    height: 60% !important;
}

.h-70p {
    height: 70% !important;
}

.h-75p {
    height: 75% !important;
}

.h-80p {
    height: 80% !important;
}

.h-90p {
    height: 90% !important;
}

.h-100p {
    height: 100% !important;
}

.centralized {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centralized-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sequence {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.embed-insights {
    max-width: 1050px;
    width: 100%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-1turn);
    }
}

.lci.lci-spin,
.ppi.ppi-spin {
    animation: spin 2s linear infinite;
    display: block;
}

.lci.lci-pulse {
    animation: spin 1s steps(8) infinite;
    display: block;
}

.table,
.pp-table {
    margin: 15px 0;
    border: none;
    width: 100%;
}

    .table thead,
    .pp-table thead {
        font-weight: 700;
        background-color: #eee;
    }

    .table tbody tr,
    .pp-table tbody tr {
        background-color: #fff;
    }

        .table tbody tr:nth-child(odd),
        .pp-table tbody tr:nth-child(odd) {
            background-color: #fafdff;
        }

        .table tbody tr:hover,
        .pp-table tbody tr:hover {
            background-color: #eee;
        }

        .table tbody tr td,
        .pp-table tbody tr td {
            font-size: 0.9rem;
            padding: 3px;
        }

            .table tbody tr td.dark,
            .pp-table tbody tr td.dark {
                font-weight: 700;
            }

    .table tfoot,
    .pp-table tfoot {
        font-weight: 700;
        background-color: #eef;
    }

        .table tfoot tr th,
        .pp-table tfoot tr th {
            text-align: left;
            padding: 3px;
            font-size: 0.8rem;
        }

form,
.form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 100%;
}

    form > .fields,
    .form > .fields {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    form > .commands,
    .form > .commands {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    form.gap-mini,
    .form.gap-mini {
        row-gap: 3px;
    }

    form.gap-tiny,
    .form.gap-tiny {
        row-gap: 7px;
    }

    form.gap-small,
    .form.gap-small {
        row-gap: 10px;
    }

.cards {
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
    flex-flow: row wrap;
}

    .cards > .lc-segment {
        -moz-column-break-inside: avoid;
        break-inside: avoid-column;
    }

.bg-hover:hover {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.05));
    border-radius: 5px;
}

.pvtUi {
    color: #333;
}

table.pvtTable {
    font-size: 10pt;
    text-align: left;
    border-collapse: collapse;
}

    table.pvtTable tbody tr th,
    table.pvtTable thead tr th {
        background-color: #fafafa !important;
        border: 1px solid #cdcdcd;
        font-size: 10pt !important;
        padding: 5px;
    }

    table.pvtTable .pvtColLabel {
        text-align: center;
    }

    table.pvtTable .pvtTotalLabel {
        text-align: right;
    }

    table.pvtTable tbody tr td {
        color: #3d3d3d;
        padding: 5px;
        background-color: #fff;
        border: 1px solid #cdcdcd;
        vertical-align: top;
        text-align: right;
    }

.pvtGrandTotal,
.pvtTotal {
    font-weight: 700;
}

.pvtVals {
    text-align: center;
    white-space: nowrap;
}

.pvtColOrder,
.pvtRowOrder {
    cursor: pointer;
    width: 15px;
    margin-left: 5px;
    display: inline-block;
}

.pvtAggregator {
    margin-bottom: 5px;
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #e4eaec !important;
    background: #fff !important;
    min-width: 20px;
    min-height: 20px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

    .pvtAxisContainer li {
        padding: 8px 6px;
        list-style-type: none;
        cursor: move;
    }

        .pvtAxisContainer li.pvtPlaceholder {
            -webkit-border-radius: 5px;
            padding: 3px 15px;
            border-radius: 5px;
            border: 1px dashed #aaa;
        }

        .pvtAxisContainer li span.pvtAttr {
            -webkit-text-size-adjust: 100%;
            background: #fff !important;
            border: 1px solid #e4eaec !important;
            padding: 2px 5px;
            white-space: nowrap;
            border-radius: 5px;
        }

.pvtTriangle {
    cursor: pointer;
    color: grey;
}

.pvtHorizList li {
    display: inline;
}

.pvtVertList {
    vertical-align: top;
}

.pvtFilteredAttribute {
    font-style: italic;
}

.pvtFilterBox {
    z-index: 100;
    width: 300px;
    border: 1px solid gray;
    background-color: #fff;
    position: absolute;
    text-align: center;
}

    .pvtFilterBox h4 {
        margin: 15px;
    }

    .pvtFilterBox p {
        margin: 10px auto;
    }

    .pvtFilterBox label {
        font-weight: 400;
    }

    .pvtFilterBox input[type=checkbox] {
        margin-right: 10px;
        margin-left: 10px;
    }

    .pvtFilterBox input[type=text] {
        width: 230px;
    }

    .pvtFilterBox .count {
        color: gray;
        font-weight: 400;
        margin-left: 3px;
    }

.pvtCheckContainer {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow-y: scroll;
    width: 100%;
    max-height: 250px;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    cursor: grab;
}

table#ngxpivottable {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: a 0px;
}

table.pvtUi {
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}

.pvtCheckContainer p {
    margin: 5px;
}

table.pvtTable {
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #e4eaec !important;
    background: #fff !important;
    min-width: 10px;
    min-height: 10px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

table.pvtTable thead tr th {
    background-color: #0487d9 !important;
    font-size: 10pt !important;
    color: #fff !important;
}

.pvtUi td.pvtOutput {
    vertical-align: top;
    overflow: scroll !important;
    overflow-x: auto !important;
    max-height: calc(100vh - 220px) !important;
    max-width: calc(100vw - 300px) !important;
    display: flex;
}

.pvtTable {
    background-color: #fff !important;
}

table.pvtTable tbody tr th {
    background-color: #fff !important;
}

.pvtAxisContainer li span.pvtAttr {
    min-width: 50px !important;
}

.zIndex {
    z-index: 5000;
}

div[class^=MuiSelect-root] {
    background-color: transparent !important;
}

div.column {
    display: flex;
    flex-direction: column;
}

div.row,
span.row {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

    div.row.full,
    span.row.full {
        width: 100%;
    }

    div.row.botton,
    span.row.botton {
        align-items: flex-end;
    }

    div.row.gap-0,
    span.row.gap-0 {
        gap: 0;
    }

    div.row.gap-1,
    span.row.gap-1 {
        gap: 5px;
    }

    div.row.gap-2,
    span.row.gap-2 {
        gap: 10px;
    }

    div.row.gap-3,
    span.row.gap-3 {
        gap: 15px;
    }

    div.row.gap-4,
    span.row.gap-4 {
        gap: 20px;
    }

    div.row.gap-5,
    span.row.gap-5 {
        gap: 25px;
    }

    div.row.right,
    span.row.right {
        justify-content: flex-end;
    }

    div.row.wrap,
    span.row.wrap {
        flex-wrap: wrap;
    }

    div.row.between,
    span.row.between {
        justify-content: space-between;
    }

div[class*=SnackbarItem-contentRoot] {
    background-color: #fff;
    color: var(--text-default);
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #cccccf;
}

div.MuiCollapse-wrapperInner .jss15 {
    background-color: #fff;
    color: var(--text-default);
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #cccccf;
}

    div.MuiCollapse-wrapperInner .jss15.jss17 {
        border: 1px solid #31cf70;
    }

    div.MuiCollapse-wrapperInner .jss15.jss18 {
        border: 1px solid #d35555;
    }

    div.MuiCollapse-wrapperInner .jss15.jss19 {
        border: 1px solid #2196f3;
    }

    div.MuiCollapse-wrapperInner .jss15.jss20 {
        border: 1px solid #be8e12;
    }

div[class*=SnackbarItem-variantError] {
    border: 1px solid #d35555;
}

div[class*=SnackbarItem-variantSuccess] {
    border: 1px solid #31cf70;
}

div[class*=SnackbarItem-variantWarning] {
    border: 1px solid #be8e12;
}

div[class*=SnackbarItem-variantInfo] {
    border: 1px solid #0580ce;
}

body > iframe {
    display: none;
}

/* svg > g > path[fill="#777777"]:nth-child(n+2) {
    filter: opacity(0);
} */

.fullspace {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.date {
    /* font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-feature-settings: "tnum" on, "Inum" on;
    letter-spacing: 0.05em; */
    font-variant-numeric: tabular-nums;
}

div.pp-input > .field,
div.pp-textarea > .field,
div.pp-dropdown > .field,
div.pp-multiselect > .field {
    border: 1px #ddd solid;
}

.MuiOutlinedInput-notchedOutline {
    border-top: 1px #ddd solid !important;
    border-right: 1px #ddd solid !important;
    border-bottom: 1px #ddd solid !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}


/*.pp-dropdown > .list.wt {
    transform: translateY(32px) translateX(3px);
}*/

.pp-dropdown > .list {
    margin-top: 50px;
}


.lc-tooltip > .body {
    filter: drop-shadow(0px 0px 3px rgba(58, 79, 135, 0.36));
}

    .lc-tooltip > .body .content {
        background-color: #f1f5fb
    }

    .lc-tooltip > .body > .arrow {
        background-color: #f1f5fb
    }

.card-page {
    display: flex;
    background-color: rgba(255,255,255,0.7);
    border-radius: 0.25rem;
    padding: 0.75rem;
    box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);
    flex-direction: column;
    /*justify-content: space-between;*/
    height: calc(100vh - 104px);
}

.container:has(.backupGoldTree) .functions .lc-iconlink {
    display: none;
}

.container:has(.backupGoldTree) .functions {
    width: 64px !important;
}

    .container:has(.backupGoldTree) .functions .lc-iconlink:has(.ppi-filter) {
        display: flex;
    }

.container:has(.backupGoldTree) .right > .row {
    display: none !important;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

/* .card-frame:hover {
    box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.2);
} */

.card-frame {
    display: flex;
    background-color: #fff;
    border-radius: 0.25rem;
    padding: 0.75rem;
    box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);
    flex-direction: column;
    justify-content: space-between;
}

.bordered {
    border: 1px solid #EBEBEB;
}

.label {
    font-size: 0.75rem;
    display: inline-block;
    margin-bottom: 4px;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: var(--background-lightest); /* Darker background color for better contrast */
    color: var(--text-default); /* White text for readability */
    font-size: 11px;
    position: absolute;
    padding: 4px 8px;
    border-radius: 4px;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    filter: drop-shadow(0px 0px 3px rgba(58, 79, 135, 0.36));
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}

.MuiTooltip-tooltip {
    filter: drop-shadow(0px 0px 3px rgba(58, 79, 135, 0.36));
    color:var(--text-default) !important;
    background-color: #f1f5fb !important;
    font-size: 11px !important;
    padding: 8px 12px !important;
    font-weight: 400 !important;
    font-family: "Ubuntu", sans-serif !important;
}

.MuiTooltip-arrow {
    color: #f1f5fb !important;
}


body .container > .page:has(.homeflex) {
    overflow-y: hidden;
}

.pp-textarea > .title {
    font-family: "Ubuntu" !important;
    color: var(--text-default)  !important;
}

.pp-textarea > .field.required {
    border-left: 3px solid var(--color-secondary-lime);
}