.button {
    height: 33px;
    width: 30px;
    background-color: #B8CCEA;
    color: #141B4D;
    font-weight: bolder;
    font-family: Inter;
    border-radius: 4px;
    border: 1px solid #e2e6f3;
    font-size: 14px;
}

.button:hover {
    background: #5969CD;
    color: white;
}

.imgSize {
    transform: scale(.85) !important;
    visibility: visible !important; 
    width:  fit-content;
    height: fit-content;
    border: solid 0.1px; 
    border-radius: 5px ;
    max-width: 52rem; 
    max-height: 31rem; 
}

.iframeSize {
    max-height: 31rem; 
    max-width: 47rem;
    height: 65.0567vh;
    width: 53vw;
}

.divOrderView {
    display: flex;
    width: auto;
    height: auto;
    align-items: center; 
    justify-content: center; 
    max-height: 28rem;
}

.divOrderAttachmentTitle {
    display: flex; 
    flex-direction: row; 
    flex-wrap: wrap; 
    align-items: center; 
    justify-content: space-between;
}

@media screen and (min-width: 2200px){
    .imgSize{
        max-width: 80rem;
        max-height: 60rem;
    }
}