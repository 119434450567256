.container .usermenu {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 44px;
  right: 0;
  font-family: "Ubuntu", sans-serif;
  z-index: 4990;
  color: var(--text-default);
}
.container .usermenu .primary {
  z-index: 5001;
}
.container .usermenu .secondary {
  z-index: 5000;
  margin-right: -1px;
}
.container .usermenu .secondary > .searchform {
  padding: 8px 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}
.container .usermenu .secondary > .searchform > i {
  font-size: 22px;
}
.container .usermenu .secondary > .searchform > input {
  padding: 4px;
  border: none;
  background-color: #fff;
  flex-grow: 1;
  font-family: "Ubuntu", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.container .usermenu .primary,
.container .usermenu .secondary {
  width: 247px;
  border: 1px solid #cccccf;
  padding: 8px 0;
  background-color: #fff;
  box-shadow: -4px 4px 18px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  overflow-x: hidden;
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 52px);
}
.container .usermenu .primary > .name,
.container .usermenu .secondary > .name {
  font-size: 12px;
  font-weight: 700;
  padding: 8px 24px;
}
.container .usermenu .primary > .company,
.container .usermenu .secondary > .company {
  font-size: 12px;
  padding: 8px 24px;
}
.container .usermenu .primary > .items,
.container .usermenu .secondary > .items {
  overflow-y: auto;
  max-height: 50vh;
  margin-right: 8px;
}
.container .usermenu .primary > .items::-webkit-scrollbar,
.container .usermenu .secondary > .items::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #eee;
}
.container .usermenu .primary > .items::-webkit-scrollbar-thumb,
.container .usermenu .secondary > .items::-webkit-scrollbar-thumb {
  background: #cccccf;
  border-radius: 4px;
}
.container .usermenu .primary > .items::-webkit-scrollbar-thumb:hover,
.container .usermenu .secondary > .items::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
.container .usermenu .primary > .items::-webkit-scrollbar-track,
.container .usermenu .secondary > .items::-webkit-scrollbar-track {
  border-radius: 4px;
}
.container .usermenu .primary > .item,
.container .usermenu .primary .items .item,
.container .usermenu .secondary > .item,
.container .usermenu .secondary .items .item {
  font-size: 12px;
  height: 38px;
  width: 100%;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;  
  font-weight: 500;
}
.container .usermenu .primary > .item:hover,
.container .usermenu .primary .items .item:hover,
.container .usermenu .secondary > .item:hover,
.container .usermenu .secondary .items .item:hover {
  background-color: #f5f5f5;
}
.container .usermenu .primary > .item i,
.container .usermenu .primary .items .item i,
.container .usermenu .secondary > .item i,
.container .usermenu .secondary .items .item i {
  font-size: 12px;
  font-weight: bolder;
  color:var(--text-light);
  padding-right:4px;
}
.container .usermenu .primary > .item.version,
.container .usermenu .primary .items .item.version,
.container .usermenu .secondary > .item.version,
.container .usermenu .secondary .items .item.version {
  color: #0580ce;
}
.container .usermenu .primary > hr,
.container .usermenu .primary .items hr,
.container .usermenu .secondary > hr,
.container .usermenu .secondary .items hr {
  margin: 8px 24px;
}