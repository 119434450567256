.homeflex {
    display: flex;
    justify-content: space-between;
    padding: 0;
    gap: 12px;
    margin: 0 auto;
    height: 100%;
}

    .homeflex .card-home {
        display: flex;
        padding: 12px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
        background-color: #fff;
        padding: 12px;
        box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.06);
        border-radius: 4px;
        transition: all 0.3s;
    }

        .homeflex .card-home:hover {
            box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.2);
        }


        .homeflex .card-home .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
        }


            .homeflex .card-home .header .group {
                display: flex;
                align-items: center;
                gap: 5px;
                flex: 1 0 0;
            }

                .homeflex .card-home .header .group .color {
                    width: .5rem;
                    height: .5rem;
                    border: 1px solid #fff;
                    background: blue;
                    border-radius: 50%;
                }

                .homeflex .card-home .header .group .title {
                    overflow: hidden;
                    color: var(--text-dark);
                    text-overflow: ellipsis;
                    font-family: "Ubuntu", sans-serif;
                    font-size: 15px;                    
                    font-weight: 400;
                    line-height: 160%; /* 25.6px */                    
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    flex: 1 0 0;
                }

            .homeflex .card-home .header .icons {
                display: flex;
                padding-left: 0px;
                justify-content: flex-end;
                align-items: center;
                gap: .25rem;
                text-align: center;
            }

                .homeflex .card-home .header .icons .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    transition: all 0.3s;
                    cursor: pointer;
                    align-content: center;
                }

                    .homeflex .card-home .header .icons .icon:hover {
                        background-color: rgba(255,255,255, .7);
                    }



                    .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content {
                        padding: .5rem 0;
                        background-color: #fff;
                    }


                    .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body > .arrow {
                        background-color: #fff;
                    }

                    .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content .item {
                        text-align: left;
                        transition: all 0.3s;
                        padding: .5rem 1.5rem;
                        font-size: .75rem;
                        color: var(--text-light);
                    }

                        .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content .item .solucao {
                            display: flex;
                            align-items: flex-start;
                            grid-gap: 0.25rem;
                            gap: 0.25rem;
                            justify-content: space-between;
                            width: 100%;
                            flex-direction: column;
                        }

                            .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content .item .solucao .label {
                                font-weight: 600;
                            }

                            .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content .item .solucao .sufixo {
                                font-size: 11px;
                            }

                        .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content .item:hover {
                            background-color: #F5F5F5;
                            color: var(--text-default);
                        }


                        .homeflex .card-home .header .icons .icon.menu .lc-tooltip > .body .content .item.permitido {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 0.75rem;
                        }




        .homeflex .card-home > .content {
            width: 100%;
        }
