.bigIcons {
  display: inline-flex;
  flex-wrap: wrap;
}

.bigIconContent {
  width: calc(25% - 1.5rem);
  height: 160px;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  background: #ffffff;
  box-shadow: 4px 4px 18px rgba(58, 79, 135, 0.06);
  border-radius: 1rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.bigIconContent .title {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 160%;
  color: #8b90a3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bigIconContent .tooltip {
  width: 15%;
  color: #0580ce;
  display: flex;
  -webkit-box-pack: end;
  flex-direction: row;
  justify-content: flex-end;
}

.bigIconContent .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.bigIconContent .icon i {
  font-size: 7rem;
}