@keyframes dragging-bg {
  from {
    background-position: 0;
  }
  to {
    background-position: 28px;
  }
}
.pp-inputfile > .title {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--text-default);
  margin-bottom: 6px;
}
.pp-inputfile > .body {
  height: 200px;
  background-color: #fff;
  border: 2px dashed #B8CCEA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.pp-inputfile > .body > input {
  display: none;
}
.pp-inputfile > .body.dragging {
  background: repeating-linear-gradient(45deg, #eee, #eee 10px, #ddd 10px, #ddd 20px);
  animation: dragging-bg 0.6s linear infinite;
}
.pp-inputfile > .body > .files {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  gap: 4px;
}
.pp-inputfile > .body > .files > .file {
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pp-inputfile > .body > .files > .file > .pp-tooltip > img {
  height: 50px;
}
.pp-inputfile > .body > .msg {
  font-size: 12px;
}
/* .pp-tooltip {
    width: calc(100% - 40px) !important;
} */