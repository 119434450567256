.pivot_view_panel_container {
    width: 100%;
    position: relative;
    justify-content: end;
}

.pivot_view_panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fafafa;
    box-shadow: -10px 0 18px 0px rgba(58, 79, 135, 0.15);
    height: calc(100vh - 80px);
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1;
    z-index: 9;
    width: 24%;
    right: 0;
    padding: 1rem;
    margin-top: -0.75rem
}
.pivot_view_panel_container > .pivot_view_panel.focusedStyle {
    position: fixed !important;
    top: 90px;
}

.pivot_view_panel_title {
    display: flex;
    justify-content: space-between;
    color: var(--text-dark);
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;

}
.pivot_view_panel_subtitle {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    color: #2E1956;
    font-family: "Ubuntu", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;

}

.pivot_view_panel_content {
    height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
}

.pivot_view_panel_list {
    height: calc(100vh - 300px);
    overflow: auto;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.pivot_view_panel_item {
    font-size: 12.8px;
    color: #2E1956;
    height: 38px;
    width: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    border-radius: 8px;
}

.pivot_view_panel_item:hover {
    background-color: #EBEBEB;
}

.pivot_view_panel_item i {
    font-size: 12px;
    font-weight: bolder;
    color: var(--text-light);
    padding-right: 4px;
}

.pivot_view_panel_footer_save {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pivot_view_panel_footer_save button {
    width: 100% !important;
}

.pivot_view_panel_footer_reset {
    display: flex;
    font-size: 12.8px;
    font-family: "Ubuntu", sans-serif;
    color: #2E1956;
}

.pivot_view_panel_list::-webkit-scrollbar {
    width: 8px;
    height: 0;
}

.pivot_view_panel_list::-webkit-scrollbar-thumb {
    background: #cccccf;
    height: 8px;
    border-radius: 4px;
}

.pivot_view_panel_list::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.pivot_view_panel_list::-webkit-scrollbar-track {
    border-radius: 4px;
}



.pivot_view_panel_item:hover {
    background-color: #EBEBEB !important;
}

.pivot_view_panel_item i {
    font-size: 12px;
    font-weight: bolder;
    color: var(--text-light);
    padding-right: 4px;
}


.pivot_view_panel_item > .functions {
    display: none;
}

.pivot_view_panel_item:hover > .functions {
    display: flex;
    padding-left:10px;
}

.pivot_view_panel_footer > .row > button.lc-button {
    min-width: 15px;
    border-radius: 4px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pivot_view_panel_item {
    font-size: 12px;
    height: 38px;
    width: 100%;
    padding-left:10px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 500;
}
a.pivot_view_panel_item {
    font-size: 12px;
    height: 38px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-weight: 500;
    border-radius: 8px;
}
p.viewLabel {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
