.dialog-actions {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
}

.text-field {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
}

.MuiDialog-paperWidthMd {
    max-width: 70% !important;
}

.css-hz1bth-MuiDialog-container {
    min-width: calc(100vw);

}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {

    min-height: calc(80vh);
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    min-height: calc(80vh);

}

.icon-button {
    cursor: pointer;
}

.icon-button .MuiIconButton-root {
    font-size: 20px;
    /* Adjust the size as needed */
}

.icon-button-send {
    padding: 0.3rem 1.2rem;
    border: 0;
    border-radius: 4px;
    height: 55px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    min-width: 55px;
    FONT-WEIGHT: 500;
    font-size: large;
}