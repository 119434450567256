.dashboardCardTitle {
    padding: 10px;
}

.webSitesCard {
    display: block;
    margin: 10px 10px;
}

.webSitesCardCMS {
    display: block;
    margin: 20px 0px;
}

.webSitesCardRow {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    padding: 5px;
    justify-content: right;
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
}

.webSitesCardRowCMS {
    display: grid;
    grid-template-columns: 1fr 6fr 2fr;
    padding: 2px;
    justify-content: flex-start;
    font-family: "Ubuntu", sans-serif;
    font-size: 12px;
    gap: 4px;
}

.webSitesCardRowLink {
    align-self: center;
}

    .webSitesCardRowLink a {
        text-decoration: none;
    }

        .webSitesCardRowLink a:hover {
            text-decoration: underline;
        }


.webSitesCardRowLinkCMS {
    align-self: center;
    max-width: 100%;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
}
    .webSitesCardRowLinkCMS a {
        text-decoration: none;
    }

        .webSitesCardRowLinkCMS a:hover {
            text-decoration: underline;
        }


.webSitesStatus {
    text-align: center;
    align-self: center;
    font-size: 12px;
    width: 120px;
    padding: 4px;
    border-radius: 10px;
}

.webSitesStatusCMS {
    text-align: center;
    align-self: center;
    font-size: 10px;
    width: 60px;
    padding: 4px;
    border-radius: 10px;
}
