.resource-price-unit {
    padding: 2px 0;
    display: flex;
    font-size: 12px;
    margin: 2px 0;
    align-items: center;
    padding: 2px 0;
    display: flex;
    font-size: 12px;
    margin: 2px 0;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource-price-unit span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource-price-unit.min-price {
    color: green;
}

.resource-price-unit:not(.min-price):not(.max-price) {
    color: grey;
}

.resource-price-unit.max-price {
    color: red;
}