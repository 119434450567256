.lc-segment {
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.lc-segment > .title {
  display: table-cell;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #8b90a3;
  margin-bottom: 1rem;
}
.lc-segment > .body {
  position: relative;
  width: 100%;
  height: auto;
}
.lc-segment > .body > .content {
  width: 100%;
}
.lc-segment > .body > .content.centralized {
  display: flex;
  flex-direction: column;
  align-items: center;
}