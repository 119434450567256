.lc-tabmenu {
  width: auto;
  height: 40px;
  display: flex;
  margin: 0 10px 20px 10px;
  justify-content: space-between;
}
.lc-tabmenu .items {
  display: flex;
}
.lc-tabmenu .items .item {
  padding: 0.6rem 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  color: #8b90a3;
  cursor: pointer;
}
.lc-tabmenu .items .item .tag {
  margin-top: 8px;
  width: 100%;
  height: 4px;
  border-radius: 0 0 0.375rem 0.375rem;
}
.lc-tabmenu .items .item:not(:last-child) {
  margin-right: 2.5rem;
}
.lc-tabmenu .items .item.active {
  color: #000;
}
.lc-tabmenu .items .item.active .tag {
  background-color: #ffb800;
}
.lc-tabmenu .items .item:hover {
  color: #000;
}
.lc-tabmenu .items .item:hover .tag {
  background-color: #c2c2c2;
}