@keyframes append {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container>.page>.notifications {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 44px;
  background-color: #fff;
  width: 400px;
  height: calc(100vh - 52px);
  right: 0;
  z-index: 1000;
  box-shadow: 0px 0px 18px rgba(58, 79, 135, 0.3);
  padding: 24px;
  animation: append 0.2s linear;
}

.container>.page>.notifications.visible {
  display: flex;
}

.container>.page>.notifications>.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container>.page>.notifications>.header>.title {
  font-family: "Ubuntu", sans-serif;
  font-weight: 200;
  font-size: 22px;
  color: var(--text-default);
}

.container>.page>.notifications>.header>.functions {
  display: flex;
}

.container>.page>.notifications>.search {
  height: 0;
  overflow: hidden;
  transition: height 0.2s linear;
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 0 5px;
  box-sizing: border-box;
  margin-top: 5px;
}

.container>.page>.notifications>.search input {
  border: 0;
  font-size: 16px;
  width: 100%;
  margin: 5px;
  background-color: transparent;
  font-family: "Ubuntu", sans-serif;
}

.container>.page>.notifications>.search input::-moz-placeholder {
  color: #ddd;
}

.container>.page>.notifications>.search input::placeholder {
  color: #ddd;
}

.container>.page>.notifications>.search i {
  font-size: 20px;
}

.container>.page>.notifications>.search.visible {
  height: 40px;
}

.container>.page>.notifications>.subheader {
  display: flex;
  flex-direction: row-reverse;
  margin: 16px 0;
}

.container>.page>.notifications>.body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  inline-size: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: -16px;
}

.container>.page>.notifications>.body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.container>.page>.notifications>.body::-webkit-scrollbar-thumb {
  background: #cccccf;
  border-radius: 0.25rem;
}

.container>.page>.notifications>.body::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.container>.page>.notifications>.body::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}

.container>.page>.notifications>.body>.message {
  padding: 16px;
  font-family: "Ubuntu", sans-serif;
  border: 2px solid #fff;
  border-radius: 8px;
  margin-right: 8px;
}

.container>.page>.notifications>.body>.message:hover {
  background-color: #f8f8f8;
}

.container>.page>.notifications>.body>.message.success {
  border: 2px solid #a5bf3e;
}

.container>.page>.notifications>.body>.message.warning {
  border: 2px solid #ffb800;
}

.container>.page>.notifications>.body>.message.danger {
  border: 2px solid #d35555;
}

.container>.page>.notifications>.body>.message.info {
  border: 2px solid #0580ce;
}

.container>.page>.notifications>.body>.message>.header {
  display: flex;
  justify-content: space-between;
}

.container>.page>.notifications>.body>.message>.header>.title {
  font-weight: 500;
  font-size: 13px;
  color: var(--text-default);
  margin-bottom: 8px;
}

.container>.page>.notifications>.body>.message>.header>.title>.ppi {
  font-size: 16px;
  margin-right: 6px;
}

.container>.page>.notifications>.body>.message>.header>.functions>.ppi {
  cursor: pointer;
}

.container>.page>.notifications>.body>.message>.when {
  color: #8b90a3;
  font-weight: 400;
  font-size: 11px;
  margin-bottom: 16px;
}

.container>.page>.notifications>.body>.message>.content {
  color: var(--text-default);
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
}

.container>.page>.notifications>.body>.message>.content>a {
  text-decoration: none;
  color: #0580ce;
}

.container>.page>.notifications>.body>.message.read>.header>.title {
  color: #8b90a3;
}

.container>.page>.notifications>.body>.message.read>.content {
  color: #8b90a3;
}

.container>.page>.notifications>.body>.allread {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.container>.page>.notifications>.body>.allread>.ppi {
  font-size: 30px;
  color: #0580ce;
  margin-bottom: 10px;
}

.container>.page>.notifications>.body>.allread>span {
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--text-default);
}

.load-more-link {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  padding: 5px;
}

.load-more-link:hover {
  text-decoration: none;
  color: #0056b3;
}