/* Rectangle */
.barTop {
  width: 1px;
  left: calc(50% - 1px / 2 + 529.5px);
  top: 0%;
  bottom: -1974.51%;
  /* Contornos */
  background: #cccccf;
  opacity: 0.5;
  /* Contornos */
  border: 1px solid #cccccf;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.descriptionDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 50px;
  margin: 1rem 0.5rem 1rem 0.5rem;
  background-color: #ffff;
  -webkit-box-shadow: 4px 4px 18px rgba(58, 79, 135, 0.06);
          box-shadow: 4px 4px 18px rgba(58, 79, 135, 0.06);
  border-radius: 8px;
  padding-left: 0.8rem;
}

.descriptionDiv.default {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.descriptionDiv .dleft {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  min-height: 50px;
  align-items: center;
}

    .descriptionDiv .dleft .order {
        color: var(--text-light);        
        padding:0 1rem 0 0;
        opacity: .7;
    }

    .descriptionDiv .right {
        display: none;
    }

.descriptionDiv:hover {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.descriptionDiv:hover .dleft {
  color: #0580CE;
}

.descriptionDiv:hover .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.makeStyles-contentsWithoutHeader {
  padding-top: 15px;
}
