.lc-pivot-segments .primary > .title {
  display: block;
  font-weight: 200;
  font-size: 26px;
  color: var(--text-default);
  line-height: 1.9375rem;
  margin-bottom: 1rem;
}
.lc-pivot-segments .primary > .secondary {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
}
.lc-pivot-segments .primary > .secondary:not(:last-child()) {
  border-bottom: 1px solid #cccccf;
}
.lc-pivot-segments .primary > .secondary .left {
  width: 50%;
}
.lc-pivot-segments .primary > .secondary .left > .title {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: var(--text-default);
}
.lc-pivot-segments .primary > .secondary .left .info {
  display: block;
  font-size: 12px;
  line-height: 160%;
  color: #8b90a3;
}
.lc-pivot-segments .primary > .secondary .left .info.warning {
  color: #be8e12;
}
.lc-pivot-segments .primary > .secondary .left .info.danger {
  color: #d35555;
}
.lc-pivot-segments .tertiary {
  width: 50%;
  max-height: 50vh;
  padding: 0.5rem;
}
.lc-pivot-segments .tertiary .list .item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1.5rem;
  color: var(--text-default);
  text-transform: uppercase;
}
.lc-pivot-segments .tertiary .list .item a {
  text-decoration: none;
  font-weight: bold;
  color: #0580ce;
}