.text {
    color: #192266;
}

.smallText {
    font-size: small;
}

.parentDiv {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 2px;
}

.parentDivSource {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 2px;
}

.parentDivSource p {
    margin-top: 7.5px;
    margin-left: 9px;
}

.divItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resultValue {
    display: flex;
    justify-content: right;
}

.textSecundary {
    color: #5b5d6f
}

.sourceName {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-top: 1.75rem;
}

.notFoundCredentialMessage p {
    margin-left: 22%;
    margin-top: 25px;
}

.infoCardRow {
    padding: 0px;
    height: auto;
    display: grid;
    grid-template-columns: 2fr 6fr 8fr 14fr;
}

.iconBlock {
    align-self: center;
    text-align: center;
}

.titleBlockContainer {
    padding: 5px;
    align-self: center;
    font-family: "Ubuntu", sans-serif;
}

.titleAlias {
    font-weight: 700;
    font-size: 20px;
}

.titleSource {
    font-size: 14px;
    padding: 8px 0 8px 0;
}

.titleStatus {
    text-align: center;
    font-size: 12px;
    width: 100px;
    padding: 4px;
    border-radius: 10px;
}

.infoBlockContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
}

.infoBlock {
    align-self: center;
    text-align: center;
}

.infoBlockLabel {
    display: block;
    font-size: 12px;
    color: grey;
    padding: 0 0 3px 0;
}

.infoBlockContent {
    font-size: 14px;
}

.infoBlock:nth-child(3) {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}

.infoBlock:last-child {
    border-right: 1px solid lightgrey;
    margin-right: 5px
}