.lc-tooltip {
  display: inline-block;
  width: inherit;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.lc-tooltip>.body {
  position: absolute;
  display: none;
  flex-direction: column;
  filter: drop-shadow(0px 0px 3px rgba(32, 47, 47, 0.36));
  z-index: 1001;
  overflow: visible;
}

.lc-tooltip>.body>.arrow {
  align-self: center;
  width: 10px;
  height: 10px;
  background-color: #e2e6f3;
  transform: rotate(45deg) translate(5px, 5px);
}

.lc-tooltip>.body .content {
  align-self: center;
  width: max-content;
  max-width: 220px;
  height: auto;
  background-color: #e2e6f3;
  border: 0;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-default);
}

.lc-tooltip:hover>.body {
  display: flex;
}

.lc-tooltip>.body.left>.content {
  align-self: flex-start;
}

.lc-tooltip>.body.center>.content {
  align-self: center;
}

.lc-tooltip>.body.right>.content {
  align-self: flex-end;
}

.lc-tooltip>.body.fullright>.content {
  align-self: flex-end;
  transform: translate(10px, 0px);
}

.lc-tooltip.top .body {
  flex-direction: column-reverse;
  transform: translateY(-100%);
}

.lc-tooltip.top .body>.arrow {
  transform: rotate(45deg) translate(-5px, -5px);
}