.entries-list {
    list-style-type: none;
    padding: 0;
}

.entry-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
    font-size: 14px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: inherit;
}

    .icon-button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .icon-button svg {
        width: 16px;
        height: 16px;
    }

.entry-item span {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
