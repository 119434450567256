.side-modal {
    position: absolute;
    top: 45px;
    right: 0;
    margin-left: auto;
    height: calc(100% - 45px);
    width: 0;
    z-index: 700;
    box-shadow: 0px 0px 18px rgba(58, 79, 135, 0.3);
    overflow-x: hidden;
    transition: width 0.1s;
}

.side-modal.blue {
    background-color: #e2e6f3;
}

.side-modal.white {
    background-color: #fafafa;
}

.side-modal.visible {
    width: 35rem;
    max-width: 100vw;
}

.side-modal>.header {
    width: 100%;
    height: 80px;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: var(--text-default);
}

.side-modal>.header span {
    font-weight: 300;
}

.side-modal>.body {
    width: 100%;
    height: calc(100vh - 152px);
    padding: 0 2rem 2rem 2rem;
}