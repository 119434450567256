.filter-panel-container {
    display: flex;
    flex-direction: column;
    padding: 20px;

    border-radius: 5px;
 
    max-width: 400px;
  }
  .ppdropdown > label {
    font-size: 0.75rem;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 4px;
}
  .filter-panel {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .filter-field {
    display: flex;
    flex-direction: column;
  }
  
 
  .input-field input,
  .filter-field select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  /* button {
    margin-top: 10px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-start;
  }
  
  button:hover {
    background-color: #0056b3;
  } */
  
  .filtered-results {
    margin-top: 20px;
    white-space: pre-wrap; /* This ensures the JSON output wraps properly */
  }
  