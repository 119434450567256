.orgchart.myChart {
  background-image: none !important
}

.orgchart.myChart>ul>li>ul li::before {
  border-top-color: var(--color-primary-blue);
}

.orgchart.myChart>ul>li>ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: var(--color-primary-blue);
}

.orgchart.myChart .oc-node .position {
  box-sizing: border-box;
  background-color: var(--color-primary-blue);
  color: #fff;
  width: 130px;
  height: 65px;
  padding: 2px;
}

.orgchart.myChart .oc-node .fullname {
  box-sizing: border-box;
  color: #FFF;
  background-color: var(--color-primary-blue);
  font-size: 0.6rem;
  width: 80px;
  height: 40px;
  padding: 0 5px;
  line-height: 40px;
  border: 1px solid var(--color-primary-blue);
  border-radius: 10px;
}

.orgchart.myChart .oc-node .fullname.disabled {
  background-color: #2b304f2d;
  color: var(--light);
}

.fullname p {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.orgchart ul li .oc-node.selected {
  background-color: rgba(119, 121, 247, 0.2) !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
}

.orgchart ul li .oc-node.selected .nodeContainer .footerNode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: 0.5s;
}

.oc-node .selected {
  background-color: rgba(119, 121, 247, 0.2);
  border-radius: 10px;
  transition: 0.5s;
}

.orgchart ul li .oc-node:hover {
  background-color: rgba(119, 121, 247, 0.2);
  border-radius: 10px;
  transition: 0.5s;
}


.oc-edge .horizontalEdge .leftEdge .oci {
  background-color: red;
  color: yellow;
}

.footerNode {
  display: none;
}



.inputLabelName {
  text-align: left;
  padding-bottom: 0.5rem;
}


.footerButtonSideModal {
  justify-content: left;
  display: flex;
}

.headerNode {
  display: flex;
  flex-direction: row;
}