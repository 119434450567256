.lc-group-header-segments-button-icon {
    width: 12px;
    /* height: 10px; */
    left: 1px;
    top: 0px;
    /* Textos / Claros */

    background: #8b90a3;
    transform: matrix(0, 1, 1, 0, 0, 0);
}

.lc-group-header-segments .chevron-visible {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.titlefix {
    width: calc(100% - 36px);
    transform: translateY(-30px);
    display: flex;
    justify-content: space-between;
}

.lc-group-header-segments .chevron-nonvisible {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.bodyGroup.collapsed {
    max-height: 0;
    display: none;
    min-width: calc(30vw);
}

.bodyGroup > div {
    width: calc(100% - 4px);
}

.bodyGroup.scroll > div {
    width: calc(100% + 4px);
}

    .bodyGroup.scroll > div::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;
        background-color: #eee;
        z-index: 99;
    }

    .bodyGroup.scroll > div::-webkit-scrollbar-thumb {
        background: #cccccf;
        border-radius: 0.25rem;
    }

        .bodyGroup.scroll > div::-webkit-scrollbar-thumb:hover {
            background: #ddd;
        }

    .bodyGroup.scroll > div::-webkit-scrollbar-track {
        border-radius: 0.25rem;
    }

.bodyGroup {
    min-width: calc(30vw);
}

.lc-group-header-segments-button {
    /* Frame 129 */

    width: 12px;
    left: 4px;
    top: 9px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 6px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.lc-group-header-segments-money {
    /* 0,00 */
    width: 100px;
    height: 22px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    text-align: right;
    letter-spacing: -0.05em;
    font-feature-settings: "tnum" on, "lnum" on;
    /* Textos / Padr�o */

    color: #525252;
    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 6px;
}

.lc-group-header-segments-title {
    /* Texto */
    max-width: calc(100vw - 620px);
    height: 22px;
    /* Texto/Padr�o - 14pt/Semibold */

    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
    /* Link */

    color: var(--text-default);
    /* Inside auto layout */

    margin: 0px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lc-group-header-segments {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px;
    width: 100%;
    height: 30px;
    background: #ebebeb;
    border-radius: 6px;
    margin: 4px 0px;
}

.lc-segment-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 27px;
}

.lc-segment-title-filter {
    display: flex;
    justify-content: flex-end;
}

.lc-group-body {
    width: 65%;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
    top: 75px;
}

.lc-group-row {
    /* Item de piv� */
    padding-left: 32px;
    padding-right: 3px;
    border-bottom: 1px solid #ebeff8;
    width: 100%;
    height: 30px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0;
    max-width: inherit;
}

.lc-group-row-inner-text {
    max-width: calc(100vw - 600px);
    height: 22px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: var(--text-default);
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lc-group-row-inner-text-sidemodal {
    max-width: calc(100vw - 600px);
    height: 22px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: var(--text-default);
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lc-group-row-inner-money {
    width: 100px;
    height: 22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: right;
    font-feature-settings: "tnum" on, "lnum" on;
    color: var(--text-default);
    margin: 0 2px 0 10px;
}

.lc-group-row-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: inherit;
    height: 22px;
    justify-content: space-between;
}

    .lc-group-row-inner > .row > .lc-iconlink.small {
        visibility: hidden;
    }

    .lc-group-row-inner:hover > .row > .lc-iconlink.small {
        visibility: visible;
    }

.lc-group-header-segments > .row > .lc-iconlink.small {
    visibility: hidden;
}

.lc-group-header-segments:hover > .row > .lc-iconlink.small {
    visibility: visible;
}

.lc-selectLabel {
    position: static;
    height: 18px;
    left: 0px;
    top: 0px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    line-height: 150%;
    font-size: 12px;
    line-height: 150%;
    margin: 3px 0px;
}

.lc-group-row > .lc-group-row-inner > .pp-tooltip {
    width: 80%;
    overflow: hidden;
}


.finops-row-title {
    border-bottom: solid 1px #E2E6F3;
    display: flex;
    justify-content: space-between;
    /* background: #EAEEF6; */
    /* border-radius: 0.25rem; */
    /*padding: 0.25rem 0.5rem;*/
    color: #2E1956;
    font-family: "Ubuntu", sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
}

.maiores-consumos-full {
    display: inherit;
}

    .maiores-consumos-full .titlefix {        
        min-height: 1.25rem;
        margin-bottom: -10px;
    }

.finops-row {
    padding: 4px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    margin-left: 12px;
    color: #2E1956;
}

    .finops-row:hover {
        background-color: #F1F5FB !important;
    }

    .finops-row:nth-child(even) {
        background: #fff;
    }

.striped .finops-row:nth-child(odd) {
    background-color: #fdfdfd;
}

.finops-row.link {
    margin-left: 0;
}

.finops-row .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}