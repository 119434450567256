.lcdashboard .lc-selectLabel {
    position: static;
    width: 55px;
    height: 18px;
    left: 0px;
    top: 0px;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    line-height: 150%;
    font-size: 12px;
    line-height: 150%;
    margin: 3px 0px;
}

.lcdashboard .lc-fixedFilter {
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
    width: 100%;
    top: 110px;
    background-color: #fafafa;
    overflow-x: hidden;
    overflow-y: auto;
    display: inline-block;
    position: fixed;
    z-index: 99;
}

.lcdashboard .echarts-for-react text[fill="#2B304F"] {
    font-feature-settings: "tnum" on, "lnum" on;
    letter-spacing: -0.05em;
}

/* .lcdashboard .lc-title {
} */

/* .lcdashboard .lc-segment-title-row { */
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 27px;*/
/* } */

.lcdashboard .tooltip-custom {
    max-width: 300px;
    word-break: break-word;
}

.lcdashboard .text-custom-resize {
    font-size: 5vw;
    word-break: break-word;
}

.lcdashboard .text-custom-resize-baseline {
    font-size: 1.5vw;
    word-break: break-word;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

.lcdashboard .text-cc {
    font-family: "Ubuntu", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.lcdashboard .grid {
    justify-content: stretch;
    display: grid;
    grid-template-columns: repeat(12, 1fr );
    grid-template-rows: 1fr;
    grid-gap: 0px;
}

/* .lcdashboard .cell {
    max-height: 90%;
    max-height: 50px;
    display: grid;
    grid-column-end: span 1;
    grid-template-rows: 1fr;
    display: grid;
    grid-column-end: span 1;
    grid-template-rows: 1fr;
    grid-row-start: 1;
    grid-row-end: span 1;
}

    .lcdashboard .cell > .fullrow {
        display: grid;
        grid-auto-flow: column;
        grid-column-start: 1;
        grid-column-end: span 12;
        grid-row-start: 1;
        grid-row-end: span 1;
    } */



.lcdashboard .grid-column {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 9fr 1fr 1fr;
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-end: span 12;
}

.lcdashboard .subtitle-dash {
    font-family: "Ubuntu", sans-serif;
    font-size: 0.75rem;
    letter-spacing: -0.04em;
    text-align: right;
    padding: 0px;
    color: #8b90a3;
}

.lcdashboard .subtitle-dash-bold {
    font-weight: 700;
}

.lcdashboard .projecao-container {
    display: flex;
    flex-direction: column;
}

.lcdashboard .projecao-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    min-width: 100%;
}

    .lcdashboard .projecao-content > .icon {
        font-size: 2.2vw;
        font-weight: bold;
    }

        .lcdashboard .projecao-content > .icon.red {
            color: var(--status-red);
        }

        .lcdashboard .projecao-content > .icon.green {
            color: var(--status-green);
        }

    .lcdashboard .projecao-content > .value {
        font-size: 1.6vw;
        font-weight: 400;
    }

        .lcdashboard .projecao-content > .value.red {
            color: var(--status-red);
        }

        .lcdashboard .projecao-content > .value.green {
            color: var(--status-green);
        }

.lcdashboard .otimizacoes-text-number {
    font-size: xx-large;
    display: flex;
}

.lcdashboard .otimizacoes-text {
    font-size: 1.6vw;
    font-weight: normal;
}

.lcdashboard .otimizacoes-symbol {
    color: white;
    font-weight: bold;
    display: flex;
    min-width: 50%;
}


.lcdashboard .alerta-container {
    display: flex;
    flex-direction: column;    
}


    .lcdashboard .alerta-container .alerta-content {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        min-width: 100%;
    }

        .lcdashboard .alerta-container .alerta-content > .icon {
            color: var(--status-red);
            font-size: 2.2vw;
            font-weight: bold;
        }


        .lcdashboard .alerta-container .alerta-content > .alerta-chart {
            color: var(--status-red);
            font-weight: bold;
            max-width: 75%;
            min-width: 75%;
        }

.lcdashboard .comparativo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

    .lcdashboard .comparativo-container.last {
        min-height: 180px;
        transition: transform 0.3s ease-out;
    }

    .lcdashboard .comparativo-container .comparativo-content {
        min-width: 160px;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        width: 50%;
    }



    .lcdashboard .comparativo-container.last .comparativo-content {
        min-width: 120px;
        width: 50%;
    }

    .lcdashboard .comparativo-container .comparativo-content .row-title {
        text-align: center;
        color:var(--text-default);
    }

    .lcdashboard .comparativo-container.last .comparativo-content .row-title {
        font-size: 0.75rem;
    }

    .lcdashboard .comparativo-container .comparativo-content .row-content {
        position: relative;
        min-height: 140px;
        width: 100%;
    }

    .lcdashboard .comparativo-container.last .comparativo-content .row-content {
        max-height: 120px;
        height: 120px;
        min-height: 120px;
    }


    .lcdashboard .comparativo-container .comparativo-content .row-subtitle {
        position: absolute;
        bottom: 35px;
        text-align: center;
        color: var(--color-secondary-green-dark);
        width: 100%;
    }

    .lcdashboard .comparativo-container.last .comparativo-content .row-subtitle {
        bottom: 33px;
        font-size: 0.75rem;
    }

    .lcdashboard .comparativo-container .comparativo-content .row-subsubtitle {
        position: absolute;
        bottom: 15px;
        font-family: "Ubuntu", sans-serif;
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: -0.04em;
        text-align: center;
        width: 100%;
        color:var(--text-default);
    }

    .lcdashboard .comparativo-container.last .comparativo-content .row-subsubtitle {
        bottom: 18px;
        font-size: 0.625rem;
    }

.lcdashboard .card-content-body:has(.maiores-consumos-full) {
    justify-content: flex-start;
}



.chart-container-home {
    position: relative;
    left: -12px;
    right: -12px;
    width: calc(100% + 24px) !important;
    height: 100% !important;
}

.consumo-mes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap:1vw;
}

    .consumo-mes .consumo {
        display: flex;
        align-items: center;
        gap: 0.5vh;
    }

        .consumo-mes .consumo .value {
            color: var(--text-default);
            font-family: "Ubuntu", sans-serif;
            font-size: 4.5vh;
            font-style: normal;
            font-weight: 400;
        }

        .consumo-mes .consumo .variation {
            padding: 2px 8px;
            border-radius: 4px;
            background: #D35555;
            color: #fff;
            font-family: "Ubuntu", sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
        }

    .consumo-mes .budget {
        display: flex;
        flex-direction: column;
        overflow:hidden;
    }

        .consumo-mes .budget .value {
            color: var(--status-red);
            text-align: right;
            font-family: "Ubuntu", sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .consumo-mes .budget .label {
            color: var(--text-light);
            text-align: right;
            font-family: "Ubuntu", sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%; /* 19.2px */
            letter-spacing: -0.48px;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
        }

.subtitle-dash-ishome {
    font-size: 1.7vh !important ;
    color: #fff !important;
}
