.lc-input {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--text-default);
}
.lc-input .label {
  color: var(--text-default);
  font-size: 0.75rem;
  line-height: 1rem;
}
.lc-input .field {
  padding-top: 5px;
  display: flex;
  align-items: center;
}
.lc-input .field input,
.lc-input .field select {
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  height: 40px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  font-size: 14px;
  background-color: #fff;
  color: var(--text-default);
  overflow: hidden;
}
.lc-input .field input.required,
.lc-input .field select.required {
  border-left: 4px solid;
  border-left-color: var(--color-secondary-lime);
  border-radius: 4px;
  border-bottom: none;
  border-right: none;
  border-top: none;
}
.lc-input .field input.bordersOff,
.lc-input .field select.bordersOff {
  border: none;
}
.lc-input .field input:-webkit-autofill,
.lc-input .field select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}
.lc-input .field input:hover,
.lc-input .field select:hover {
  background-color: #fafafa;
}
.lc-input .field.multi select {
  height: 150px;
  overflow: scroll;
}
.lc-input .field .lci {
  font-size: 1.5rem;
  position: absolute;
  right: 0.5rem;
}
.lc-input .field .invalid {
  border: 1px solid var(--status-red);
}
.lc-input .field.icon > input, .lc-input .field.icon > select {
  padding: 4px 40px 4px 8px;
}

input[type="file"] {
  background-color: #EAEEF6;
  display: none;
}

span.spanNameFile {
  font-size: 12px;
  color: var(--primary);
  
}