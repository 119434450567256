.footerModalAnexos {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .MuiButton-label {
    padding: 10px;
  }
  
  .divOrderAttachmentSolicitation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  
  .divShowfile {
    background-color: #fff;
    display: flex;
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  
  
  .divOrderAttachmentShow {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-right: 10px;
  }
  
  .spanLabel {
    font-family: "Ubuntu", sans-serif;
    color: var(--text-default);
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 100%;
    margin: 5px 0px;
    cursor: pointer;
    font-size: 10px;
  }
  
  .spanSize {
    font-family: "Ubuntu", sans-serif;
    color: #8B90A3;
    font-weight: 400;
    margin-bottom: 5px;
    width: max-content;
    max-width: 100%;
    cursor: pointer;
    font-size: 8px;
  }