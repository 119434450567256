.cardRow {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
  background-color: white;
  -webkit-box-shadow: 0.25rem 0.25rem 1.125rem rgba(58, 79, 135, 0.06);
          box-shadow: 0.25rem 0.25rem 1.125rem rgba(58, 79, 135, 0.06);
  border-radius: 1rem;
}