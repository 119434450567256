.detailsSystemPlataform {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
}
.evenly{
    align-content: space-evenly;    
}
.headerSystemDetails {
    width: 100%;
    display: flex;
    background-color: #f1f5fb;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 10px;
    /* box-shadow: 4px 4px 18px 0px rgba(58, 79, 135, 0.2); */
}

.systemDetail {
    display: flex;
    flex-direction: column;
    font-size: .75rem;
    justify-content: space-around;
}

.systemDetail>h3 {
    margin-left: 10px;
}

.systemDetail>div {
    display: flex;
    align-items: center;
}

.systemDetail>P>span.systemDetailLabel {
    color: #9196a9
}

.severityLabelDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 20px;
    border-radius: 5px;
    color: #fff;
    font-size: .7rem;
    text-overflow: ellipsis;
    max-width: 110px;
}

.bodySystem h4 {
    margin: 1rem 0;
    color: #8B90A3;
    font-size: 16px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
}

.overflowEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.operationalSystem {
    display: flex;
    align-items: center;
    justify-self: center;
    font-size: .75rem;
}

.severityBox {
    min-width: 1.5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 1.5rem;
    color: #ffffff;
    padding: 2px 3px;
}

.agentStatus {
    display: flex;
    align-items: center;
    justify-content: center;

}

.bodySystem > .lc-datatableinfinite > .body > .row >.cells, .bodySystem > .lc-datatableinfinite > .header > .row >.cells {
    margin-left: 0px;
}
    
