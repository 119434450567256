.headerLog {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
    border-bottom: 2px solid #e9ecf6;
    padding: 0px 5px;
}

    .headerLog div {
        font-weight: bold;
        margin-bottom: .4rem;
    }

.bodyLog > .row {
    display: flex;
    font-size: .8rem;
    font-weight: 400;
    justify-content: space-between;
    padding: 0px 5px;
    border-radius: 10px;
}

.headerLog > .resources {
    max-width: 50%;
    min-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerLog > .resources_focus {
    max-width: 25%;
    min-width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerLog > .eventTime_focus {
    max-width: 12.5%;
    min-width: 12.5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bodyLog > .row:hover {
    background-color: #F1F5FB;
}

.bodyLog > .row > .resources {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 50%;
}

.bodyLog > .row > .resources_focus {
    max-width: 25%;
    min-width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

 .halfColumn {
    max-width: 12.5%;
    min-width: 12.5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.database-list {
    background-color: #fff;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.database-list-header {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
    border-bottom: 2px solid #e9ecf6;
    padding: 0px 5px;
    font-weight: bold;
    margin-bottom: .4rem;
}

.database-list-header div {
    margin-right: 20px;
    cursor: pointer;
}

.database-list-body {
    display: flex;
    flex-direction: column;
}

.database-item {
    padding: 10px;
        
    display: flex;
    background-color: #fff;

}

.database-item div {
    margin-right: 20px;
}
